import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import { ITopic } from 'types';
import { User } from '@koopajs/mui';
import { useLocale } from '@koopajs/react';
import { theme } from 'components/Core/theme';

interface INominationsFieldProps {
  topic: ITopic;
}

export const NominationsField: React.FC<INominationsFieldProps> = (props) => {
  const { topic } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.TopicCard.NominationsField';

  return (
    <>
      {(topic.meetingPresidentId || topic.meetingSecretaryId) && (
        <Stack direction="row" sx={{ borderRadius: '4px', pt: 1 }}>
          {topic.meetingPresidentId && (
            <Box width="50%">
              <Typography variant="caption">{t(keyPrefix + '.assemblyPresident')}</Typography>
              <List
                sx={{
                  '@media print': {
                    p: 0
                  }
                }}
              >
                <ListItem
                  disableGutters
                  sx={{
                    '@media print': {
                      p: 0
                    }
                  }}
                >
                  <User
                    id={topic.meetingPresidentId}
                    variant="text-only"
                    sxText={{
                      '@media print': {
                        ...theme.typography.body2
                      }
                    }}
                  />
                </ListItem>
              </List>
            </Box>
          )}
          {topic.meetingSecretaryId && (
            <Box width="50%">
              <Typography variant="caption">{t(keyPrefix + '.assemblySecretary')}</Typography>
              <List
                sx={{
                  '@media print': {
                    p: 0
                  }
                }}
              >
                <ListItem
                  disableGutters
                  sx={{
                    '@media print': {
                      p: 0
                    }
                  }}
                >
                  <User
                    id={topic.meetingSecretaryId}
                    variant="text-only"
                    sxText={{
                      '@media print': {
                        ...theme.typography.body2
                      }
                    }}
                  />
                </ListItem>
              </List>
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};
