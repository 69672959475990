import { DateTime } from 'luxon';

export const parseUTCDateTime = (date: string, time: string): Date => {
  const dateLocal = DateTime.fromISO(date, { zone: 'utc' }).setZone('local');

  const timeLocal = DateTime.fromFormat(time, 'HH:mm', { zone: 'local' });

  // Combine date and time  in the local time zone
  const localDateTime = dateLocal.set({
    hour: timeLocal.hour,
    minute: timeLocal.minute,
    second: 0,
    millisecond: 0
  });

  // Convert the local DateTime to UTC
  const utcDateTime = localDateTime.toUTC();

  return utcDateTime.toJSDate();
};
