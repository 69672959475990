import { IMeeting } from 'types';
import { Box, Typography, Link as MUILink, Stack } from '@mui/material';
import { useLocale } from '@koopajs/react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface IMeetingLocationInformation {
  meeting: IMeeting;
}

export const MeetingLocationInformation: React.FC<IMeetingLocationInformation> = (props) => {
  const { meeting } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.MeetingLocationInformation';

  if (!(meeting.location?.type || meeting.location?.url || meeting.location?.address)) return null;

  return (
    <Stack gap={0.5}>
      {/* MEETING TYPE & URL */}
      <Stack flexDirection="row" alignItems="center">
        <Typography variant="body2" color="text.secondary">
          {t(`common:locationType.${meeting.location?.type}`)}
        </Typography>
        {meeting.location?.url && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                mx: 1
              }}
            >
              &#183;
            </Box>
            <MUILink
              href={meeting.location?.url}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'link.main'
              }}
              target="_blank"
              rel="noopener"
            >
              <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2">{t(keyPrefix + '.meetingLink')}</Typography>
            </MUILink>
          </Box>
        )}
      </Stack>
      {/* ADDRESS */}
      {meeting.location?.address && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {meeting.location?.address}
        </Typography>
      )}
    </Stack>
  );
};
