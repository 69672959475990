import { Box, SxProps, Theme } from '@mui/material';
import { useFeatureGate } from '@statsig/react-bindings';
import { useWorkspaceShow } from '@koopajs/react';
import { useLocale } from '@koopajs/react';
import { theme } from 'components/Core/theme';
const DOCUMENT_ANNOTATION_APP_URL: string = process.env.REACT_APP_DOCUMENT_ANNOTATION_APP_URL!;

interface IDocumentAnnotation {
  documentId: string;
  sx?: SxProps<Theme>;
  sxContainer?: SxProps<Theme>;
  allowAnnotate?: boolean;
  testId?: string;
}

export const DocumentAnnotation: React.FC<IDocumentAnnotation> = (props) => {
  const { documentId, sx: sxProp, sxContainer, testId, allowAnnotate = false } = props;

  const { locale } = useLocale();

  const { workspace } = useWorkspaceShow();

  const { value: isDocumentAnnotationsEnabled } = useFeatureGate('document_annotations');

  const isAnnotateEnabled = allowAnnotate && isDocumentAnnotationsEnabled;

  const documentUrl = `${DOCUMENT_ANNOTATION_APP_URL}/${workspace?.id}/d/${documentId}?locale=${locale}${
    isAnnotateEnabled ? '' : '&readonly=true'
  }`;

  const sx = {
    width: '100%',
    border: `1px solid ${theme.palette.customGrey?.light}`,
    borderRadius: '4px',
    ...sxProp
  };

  return (
    <Box sx={sxContainer}>
      <Box
        className="rr-block"
        data-cy={testId}
        component="iframe"
        sx={sx}
        src={documentUrl}
        data-read-only={isAnnotateEnabled ? 'false' : 'true'}
      />
    </Box>
  );
};
