import { IMeeting, IMinute } from 'types';
import { Stack, Typography, Box, List } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';
import { useCommitteeName } from '../hooks/useCommitteeName';
import { TimeStartEnd } from 'components/TimeStartEnd';
import { UsersWithTitlesList } from 'components/UsersWithTitlesList';

interface IMinutesHeaderCard {
  minutes: IMeeting | IMinute;
}

//Component used for Review meeting, Sign minutes, Dialog View minutes book

export const MinutesHeaderCardPrint: React.FC<IMinutesHeaderCard> = (props) => {
  const { minutes } = props;

  const { t } = useLocale();
  const keyPrefix = 'MeetingEdit.MeetingStaticCard';
  const presentParticipants = minutes.participants?.filter((p) => p.isPresent);
  const absentParticipants = minutes.participants?.filter((p) => !p.isPresent);

  const minutesType = useCommitteeName(minutes.committeeId as string);

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(minutes.activeCommitteeMembers);

  return (
    <Box
      sx={{
        display: 'flex'
      }}
    >
      <Stack sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginBottom: '14px'
          }}
        >
          <Typography variant="overline">{t('common:boardMinutes')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1, flexWrap: 'wrap' }}>
            <Typography variant="h6">
              {' '}
              <DateTimeStartEnd isoStringDate={minutes.startDateTime} dateFormat="DATE_HUGE" />
            </Typography>{' '}
            <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
              {' | '}
            </Typography>
            <Typography variant="h6"> {minutesType}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Typography variant="body2">{minutes?.title}</Typography>
            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              &#183;
            </Typography>

            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              <TimeStartEnd isoStringStart={minutes?.startDateTime} isoStringEnd={minutes?.endDateTime} />
            </Typography>

            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              &#183;
            </Typography>

            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              {t(`common:locationType.${minutes?.location?.type}`)}
            </Typography>
          </Box>
          {minutes.location?.address && (
            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              {minutes?.location?.address}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {presentParticipants && presentParticipants?.length > 0 && (
            <Box>
              <Typography variant="caption">
                {t(keyPrefix + '.presentParticipants', { count: presentParticipants.length })}
              </Typography>
              <List sx={{ pt: 0 }}>
                <UsersWithTitlesList
                  userIds={presentParticipants?.map((user) => user.userId)}
                  committeeMembersTitles={committeeMembersTitlesObj}
                />
              </List>
            </Box>
          )}
          {absentParticipants && absentParticipants?.length > 0 && (
            <Box>
              <Typography variant="caption">
                {t(keyPrefix + '.absentParticipants', { count: absentParticipants.length })}
              </Typography>
              <List sx={{ pt: 0 }}>
                <UsersWithTitlesList
                  userIds={absentParticipants?.map((user) => user.userId)}
                  committeeMembersTitles={committeeMembersTitlesObj}
                />
              </List>
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
