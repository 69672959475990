import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { useUserUpdate, FormController, useUserShow } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { Button, Box, Stack } from '@mui/material';
import { ICoreProps, ErrorMessage } from '@koopajs/mui';
import { useLocale } from '@koopajs/react';
import { NewCheckbox } from '../../../temp/NewCheckbox';

export interface IContentTOCProps extends ICoreProps {
  moveNext: () => void;
  movePrevious: () => void;
  latestTOCVersion: string;
  appName: string;
  commercialWebsiteUrl: string;
  appUrl: string;
  privacyPolicyUrl: string;
}

export const ContentTOC: React.FC<IContentTOCProps> = (props: IContentTOCProps) => {
  const {
    moveNext,
    movePrevious,
    latestTOCVersion,
    appName,
    commercialWebsiteUrl,
    appUrl,
    privacyPolicyUrl,
    i18n
  } = props;

  const { t, setLocale } = useLocale();
  const keyPrefix = i18n?.keyPrefix!;

  const { user, isProcessing: isProcessingUserShow } = useUserShow();

  const terms: { title?: string; text?: string }[] = t(keyPrefix + '.terms', {
    returnObjects: true,
    appName,
    commercialWebsiteUrl,
    appUrl,
    privacyPolicyUrl
  });

  const { updateUser, isProcessing: isProcessingUserUpdate, errorMessage } = useUserUpdate();

  const isProcessing = isProcessingUserShow || isProcessingUserUpdate;

  const onSubmitForm = useCallback(async (): Promise<boolean> => {
    const isSuccessful = await updateUser({
      latestAcceptedTerms: latestTOCVersion
    });

    if (isSuccessful) {
      moveNext();
      TrackEvent('onboarding-toc');
    }

    return isSuccessful;
  }, []);

  return (
    <Box>
      <Typography>{t(keyPrefix + '.title')}</Typography>

      <ErrorMessage error={errorMessage} />

      <Box>
        <Box>
          <Box
            sx={{
              maxHeight: 400,
              overflow: 'auto',
              fontSize: 12
            }}
          >
            {/* TODO: should there be a backup here? */}
            {terms.map((item) => {
              return (
                <p className="no-select" key={item.title}>
                  {item?.title}
                  {item?.title && item?.text && <br />}
                  {item?.text}
                </p>
              );
            })}
          </Box>
          <Box sx={{ mt: 1 }}>
            <FormController onSubmit={onSubmitForm}>
              <NewCheckbox
                isOptional={false}
                i18n={{ keyPrefix: `${keyPrefix}.Checkbox` }}
                name="isAccepted"
              />
              <Stack direction="row" spacing={2}>
                <Button onClick={movePrevious} disabled={isProcessing}>
                  {t('common:labelBack')}
                </Button>{' '}
                <Button variant="contained" color="primary" disabled={isProcessing} type="submit">
                  {t('common:labelNext')}
                </Button>
              </Stack>
            </FormController>
          </Box>
          <Box />
        </Box>
      </Box>
    </Box>
  );
};
