import { IMeeting } from 'types';
import { useParamsKey, useResourceShow } from '@koopajs/react';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { BoardMemberView } from 'components/BoardMemberView';
import { BoardMemberViewProvider } from 'components/BoardMemberView/BoardMemberViewProvider';

export const MeetingBoardMemberViewPage: React.FC = () => {
  const meetingId = useParamsKey('meetingId');
  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId
  });

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  }

  return (
    <BoardMemberViewProvider meeting={meeting}>
      <BoardMemberView />
    </BoardMemberViewProvider>
  );
};
