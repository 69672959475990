import { Alert, Link as MuiLink } from '@mui/material';
import { useLocale } from '@koopajs/react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

export const AnnouncementBanner: React.FC = () => {
  const { t } = useLocale();

  return (
    <Alert
      sx={{
        borderRadius: '0',
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: 'tertiary.main',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'tertiary.lighter',
        py: 0
      }}
      icon={<VolunteerActivismIcon sx={{ color: 'primary.main', fontSize: '18px' }} />}
    >
      <MuiLink
        href={t('Header.bannerCtaUrl')}
        sx={{
          alignItems: 'center',
          textDecoration: 'none',
          color: 'link.main'
        }}
        target="_blank"
        rel="noreferrer"
      >
        {t('Header.bannerCtaMessage')}{' '}
        <OpenInNewIcon fontSize="small" sx={{ fontSize: '18px', verticalAlign: 'text-bottom' }} />
      </MuiLink>
    </Alert>
  );
};
