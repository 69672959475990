import { useResourceShow } from '@koopajs/react';
import { List, Typography, SxProps, Theme } from '@mui/material';
import { IMeeting, ITopic } from 'types';
import { PastMeetingApprovalItem } from './PastMeetingApprovalItem';
import { useLocale } from '@koopajs/react';
import { ErrorMessage } from '@koopajs/mui';
import { usePastMeetingsApprovalList } from './usePastMeetingsApprovalList';

interface IPastMeetingsToApproveListProps {
  meetingId: string;
  topicId: string;
  topic: ITopic;
  isViewPrint?: boolean;
  isEditable?: boolean;
  showApprovedMeetings?: boolean;
  hideLabel?: boolean;
  sxTitle?: SxProps<Theme>;
}
export const PastMeetingsToApproveList: React.FC<IPastMeetingsToApproveListProps> = (props) => {
  const { meetingId, topicId, isViewPrint, isEditable, topic, showApprovedMeetings, hideLabel, sxTitle } =
    props;

  const { t } = useLocale();

  // workaround for error message
  const { errorMessage } = useResourceShow({
    path: `/meetings`,
    id: meetingId,
    useCache: true
  });

  let minutes: IMeeting[] = [];

  const { allMeetings, meetingsNotApproved } = usePastMeetingsApprovalList({ topic });

  minutes = showApprovedMeetings ? allMeetings : meetingsNotApproved;

  return (
    <>
      {minutes.length > 0 && (
        <>
          {!hideLabel && (
            <Typography sx={{ opacity: 0.8, mt: !isViewPrint ? 1 : null, ...sxTitle }}>
              {t('Components.TopicCard.PastMeetingsToApproveList.labelPastMinutesToAdopt')}
            </Typography>
          )}

          {isEditable && <ErrorMessage error={errorMessage} />}
          <List sx={{ mt: '0px!important', pt: 0.5 }}>
            {minutes.map((minutes) => {
              return (
                <PastMeetingApprovalItem
                  minutes={minutes}
                  meetingId={meetingId}
                  topicId={topicId}
                  key={minutes.id}
                  isViewPrint={isViewPrint}
                  isEditable={isEditable}
                />
              );
            })}
          </List>
        </>
      )}
    </>
  );
};
