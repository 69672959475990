import React, { useCallback } from 'react';
import { Dialog, ErrorMessage, Form } from '@koopajs/mui';
import { useResourceCreate, useComponentVisibility, useResourceShow } from '@koopajs/react';
import { Box, Typography, Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { IMeeting } from 'types';
import { ParticipantsList } from '../ParticipantsList';
import { TextField } from 'components/temp/TextFieldTemp';
import { TextMultiLine } from 'components/temp/TextMultiLineTemp';

export const SendMeeting: React.FC = () => {
  const { t } = useLocale();

  const keyPrefix = 'MeetingEdit.DialogSendMeeting';
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendMeetingDialog = useComponentVisibility('sendMeeting');
  const { meeting } = sendMeetingDialog.getContext() as { meeting: IMeeting };

  const { createResource } = useResourceCreate({
    path: `/meetings/${meeting?.id}/invitations`,
    customReducer: {
      path: { resourceType: '/meetings', resourceId: meeting?.id },
      //response has info about  updated meeting timeline
      mapping: (meeting: object, response: object) => {
        const responseObject = response as { timeline: { [k: string]: string } };
        return { ...meeting, ...responseObject.timeline };
      }
    }
  });

  // hacky way to get error message when using custom reducer
  const { errorMessage } = useResourceShow({ path: `/meetings`, id: meeting?.id });

  const handleSubmit = useCallback(
    async (data: object): Promise<boolean> => {
      const formData = data as { message?: string };
      if (!formData.message) {
        formData.message = undefined;
      }

      const res = await createResource(data);

      if (res) {
        enqueueSnackbar(t(keyPrefix + '.snackbarSendInvitationSuccess'), {
          variant: 'success',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });

        TrackEvent('invitation-sent');
      }

      return res;
    },
    [meeting?.id]
  );
  const meetingReminderOptions = [1, 3, 7, 14].map((option) => ({
    id: `${option}`,
    label: t(`${keyPrefix}.meetingReminderRadio.optionLabel`, { count: option })
  }));

  return (
    <Dialog.Form
      dialogKey="sendMeeting"
      onSubmit={handleSubmit}
      i18n={{ keyPrefix: keyPrefix + '.DialogForm' }}
      maxWidth="md"
      leftAction={<Button onClick={sendMeetingDialog.setHidden}>{t('common:labelCancel')}</Button>}
      dialogProps={{ className: 'rr-block' }}
    >
      <ErrorMessage error={errorMessage} />
      <Typography sx={{ my: 2 }}>{t(keyPrefix + '.Form.sendTo')}</Typography>
      <Box sx={{ my: 2 }}>
        <ParticipantsList participants={meeting?.participants} chipVariant="outlined" />
      </Box>
      <TextField
        name="title"
        validationRules={{ maxLength: 1000 }}
        i18n={{ keyPrefix: keyPrefix + '.Form.FieldTitle' }}
      />
      <Form.Checkbox
        name="isAgenda"
        i18n={{ keyPrefix: keyPrefix + '.Form.FieldAgenda' }}
        isOptional={true}
        isChecked={true}
      />
      <TextMultiLine
        rows={4}
        name="message"
        validationRules={{ maxLength: 1000 }}
        isOptional={true}
        i18n={{ keyPrefix: keyPrefix + '.Form.FieldMessage' }}
      />

      <Form.Radio
        name="reminder"
        i18n={{ keyPrefix: keyPrefix + '.meetingReminderRadio' }}
        options={meetingReminderOptions}
        row
        sx={{ mt: 1, pt: 1 }}
        variant="dense"
        isOptional={true}
      />
    </Dialog.Form>
  );
};
