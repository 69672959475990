import { Stack, Button } from '@mui/material';
import { IMeetingTopicCardEditableProps, MeetingTopicCardEditable } from '.';
import { MoveButton } from './MoveButton';
import { SystemStyleObject } from '@mui/system';
import { checkIsUnmovableTopicType } from 'utils/topicTypeArrays';

interface IMeetingTopicCardEditableMovableProps extends IMeetingTopicCardEditableProps {
  meetingId: string;
  nextTopicId?: string;
  previousTopicId?: string;
}

export const MeetingTopicCardEditableMovable: React.FC<IMeetingTopicCardEditableMovableProps> = (props) => {
  const {
    topic,
    handleSetTopicEditable,
    order,
    isEditable,
    users,
    participants,
    isMeetingStarted,
    onSubmitSuccess,
    meetingId,
    nextTopicId,
    previousTopicId,
    activeCommitteeMembers
  } = props;

  const isUnmovableTopic = checkIsUnmovableTopicType(topic.type);

  const buttonSx: SystemStyleObject = {
    minWidth: 0,
    padding: { xs: 0, md: '6px 8px' }
  };

  // dummy (invisible) button so spacing is the same
  const renderDummyButton = (): React.ReactNode => {
    return (
      <Button sx={{ visibility: 'hidden', ...buttonSx, py: '0 !important', height: '0px' }}>
        <span style={{ height: '24px', width: '24px' }} />
      </Button>
    );
  };

  return (
    <Stack
      direction="row"
      sx={{ px: { xs: '14px', md: 3 } }}
      data-cy="meeting-prepare_topic-card_moveable-container"
    >
      <Stack justifyContent="center" sx={{ pr: { xs: '14px', md: 3 } }}>
        {/* move up */}
        {previousTopicId && !isUnmovableTopic ? (
          <MoveButton
            topicToMoveId={topic.id}
            previousOrNextTopicId={previousTopicId}
            direction="previous"
            meetingId={meetingId}
            sx={buttonSx}
          />
        ) : (
          renderDummyButton()
        )}
        {/* move down */}
        {nextTopicId && !isUnmovableTopic ? (
          <MoveButton
            topicToMoveId={topic.id}
            previousOrNextTopicId={nextTopicId}
            direction="next"
            meetingId={meetingId}
            sx={buttonSx}
          />
        ) : (
          renderDummyButton()
        )}
      </Stack>
      <MeetingTopicCardEditable
        topic={topic}
        handleSetTopicEditable={handleSetTopicEditable}
        order={order}
        isEditable={isEditable}
        users={users}
        participants={participants}
        isMeetingStarted={isMeetingStarted}
        onSubmitSuccess={onSubmitSuccess}
        sx={{ px: 0, py: 1, flexGrow: 1, my: 'auto' }}
        activeCommitteeMembers={activeCommitteeMembers}
      />
    </Stack>
  );
};
