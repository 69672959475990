import { Box, Stack, Chip, Typography } from '@mui/material';
import { ITopic } from 'types';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { TopicChipConditions } from 'components/TopicChipConditions';
import { useLocale } from '@koopajs/react';
import { formatTime } from 'utils/DateTime/formatTime';
import { DateTime } from 'luxon';
import { User } from '@koopajs/mui';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';

interface IBoardMemberViewTopicCardAccordionSummaryChildren {
  topic: ITopic;
  linkDocumentsCount?: number;
  fileDocumentsCount?: number;
  index: number;
  pastMinutesToApproveItemIndex?: number;
}

export const BoardMemberViewTopicCardAccordionSummaryChildren: React.FC<
  IBoardMemberViewTopicCardAccordionSummaryChildren
> = (props) => {
  const { topic, linkDocumentsCount, fileDocumentsCount, index, pastMinutesToApproveItemIndex } = props;

  const { t, locale } = useLocale();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'flex-start', sm: 'center' },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        width: '100%',
        minWidth: 0,
        mr: 3
      }}
      gap={{ xs: 1, lg: 3 }}
    >
      <Box>
        {/* TODO: separate component? */}
        <Chip
          variant="outlined"
          label={
            topic.calculatedTopicStartTime ? (
              formatTime({
                isoString: DateTime.fromSeconds(topic.calculatedTopicStartTime).toISO(),
                locale
              })
            ) : (
              <TimerOutlinedIcon sx={{ fontSize: '16px' }} />
            )
          }
          sx={{
            backgroundColor: '#FFFFFF',
            ...(topic.calculatedTopicStartTime
              ? { fontVariantNumeric: 'tabular-nums' }
              : {
                  '& .MuiChip-label': {
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '50px'
                  }
                })
          }}
        />
      </Box>
      <Typography
        component="h2"
        variant="body1"
        sx={{
          whiteSpace: 'normal',
          fontWeight: 500
        }}
        data-cy="topic-card-accordion-title"
      >
        {index + 1}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
      </Typography>
      <Box sx={{ display: 'flex', gap: { xs: 1, sm: 3 } }}>
        {topic.assignedTo && (
          <User
            variant="avatar-tooltip-only"
            sxAvatar={{
              width: '32px',
              height: '32px',
              border: '1px solid',
              borderColor: 'customGrey.light'
            }}
            id={topic?.assignedTo || ''}
          />
        )}
        <TopicChipConditions topicType={topic.type} isOutlined />
      </Box>
      {Boolean(linkDocumentsCount) && (
        <Box data-cy="topic-card_accordion-links-count">
          <Chip
            icon={<LinkIcon />}
            label={t('common:numberLinks', {
              count: linkDocumentsCount
            })}
            variant="outlined"
            sx={{ borderRadius: '4px', backgroundColor: '#FFFFFF' }}
          />
        </Box>
      )}
      {Boolean(fileDocumentsCount) && (
        <Box data-cy="topic-card_accordion-documents-count">
          <Chip
            icon={<FilePresentIcon />}
            label={t('common:numberDocuments', {
              count: fileDocumentsCount
            })}
            variant="outlined"
            sx={{
              borderRadius: '4px',
              backgroundColor: '#ffffff'
            }}
          />
        </Box>
      )}

      {topic.type === 'pastMinutesApproval' && Boolean(topic?.pastMeetingsToApprove?.length) && (
        <Chip
          icon={<AccessTimeIcon />}
          label={t('common:numberMinutes', {
            current: (pastMinutesToApproveItemIndex || 0) + 1,
            count: topic.pastMeetingsToApprove?.length
          })}
          sx={{ borderRadius: '4px', backgroundColor: '#FFFFFF' }}
          variant="outlined"
        />
      )}
    </Box>
  );
};
