import { IMinuteRef } from 'types';
import { Typography, Box } from '@mui/material';
import { useLocale, useResourceReference } from '@koopajs/react';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DateTimeStartEnd } from './DateTimeStartEnd';
import { TimeStartEnd } from './TimeStartEnd';

interface ITopicMinutesDetailsCard {
  minuteId: string;
  title: string;
  resolutionNumber?: string;
}

export const TopicMinutesDetailsCard: React.FC<ITopicMinutesDetailsCard> = (props) => {
  const { minuteId, title, resolutionNumber } = props;

  const minute = useResourceReference<IMinuteRef>(`ref:minute:${minuteId}`);

  const minutesType = useCommitteeName(minute?.committeeId || '');

  const { t } = useLocale();

  if (!minute) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant="overline"
        data-cy="view-resolution-dialog_resolution-number"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0px', whiteSpace: 'nowrap' }}
      >
        {title} {resolutionNumber && `#${resolutionNumber}`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 0, sm: 1 },
          mb: '4px',
          flexWrap: 'wrap'
        }}
      >
        <Typography variant="h6">
          <DateTimeStartEnd isoStringDate={minute?.startDateTime} dateFormat="DATE_HUGE" />
        </Typography>{' '}
        <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
          {' | '}
        </Typography>
        <Typography variant="h6"> {minutesType}</Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: { xs: 0, sm: 1 },
            flexWrap: 'wrap',
            flexDirection: { xs: 'column', sm: 'row' }
          }}
        >
          <Typography
            variant="body2"
            data-cy="topic-minutes-detail-card_minutes-title"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: '0px',
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            {minute?.label}
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.6, display: { xs: 'none', sm: 'block' } }}>
            &#183;
          </Typography>

          <Typography variant="body2" sx={{ opacity: 0.6 }}>
            <TimeStartEnd isoStringStart={minute?.startDateTime} isoStringEnd={minute?.endDateTime} />
          </Typography>

          <Typography variant="body2" sx={{ opacity: 0.6, display: { xs: 'none', sm: 'block' } }}>
            &#183;
          </Typography>

          <Typography variant="body2" sx={{ opacity: 0.6 }}>
            {t(`common:locationType.${minute?.location?.type}`)}
          </Typography>
        </Box>
        {minute.location?.address && (
          <Typography
            variant="body2"
            sx={{
              opacity: 0.6,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: '0px',
              whiteSpace: 'nowrap',
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            {minute?.location?.address}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
