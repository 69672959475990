import { useCallback, useState } from 'react';
import { useLocale, useResourceShow } from '@koopajs/react';
import { Button, Stack, Typography, IconButton, Box, Tooltip, InputAdornment, Alert } from '@mui/material';
import { ErrorMessage, Form, User } from '@koopajs/mui';
import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  TimerOutlined as TimerOutlinedIcon
} from '@mui/icons-material';
import { checkIsInCameraTopicType, checkIsSmartTopicType } from 'utils/topicTypeArrays';
import { calculateDuration } from 'utils/calculateDuration';
import { InCameraField } from '../../TopicCard/InCameraField';
import { useTopic } from 'components/hooks/useTopic';
import { ITopicCardProps } from '../../../types';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { checkIsGenericTopicType } from 'utils/topicTypeArrays';
import { DocumentList } from 'components/DocumentList';
import { PastMeetingsToApproveList } from '../../TopicCard/PastMinutesApprovalField/PastMeetingsToApproveList';
import RichTextMultiLine from 'components/temp/RichTextMultiLine';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { TextField } from 'components/temp/TextFieldTemp';
import { SmartTopicLabel } from '../../TopicCard/SmartTopicLabel';
import { DateTime } from 'luxon';
import { PastMinutesApprovalFieldFormController } from '../../TopicCard/PastMinutesApprovalField/FormController';
import { SystemStyleObject } from '@mui/system';
import { IUserPublicProfile } from '@koopajs/app';
import { theme } from 'components/Core/theme';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { TopicChipConditions } from 'components/TopicChipConditions';
import { TimestampChip } from 'components/TimestampChip';

const durations: number[] = [
  300, // 5 mins
  600, // 10 mins
  900, // 15 mins
  1200, // 20 mins
  1500, // 25 mins
  1800, // 30 mins
  2100, // 35 mins
  2400, // 40 mins
  2700, // 45 mins
  3000, // 50 mins
  3300, // 55 mins
  3600 // 60 mins
];

export interface IMeetingTopicCardEditableProps extends ITopicCardProps {
  users: IUserPublicProfile[];
  isMeetingStarted?: boolean;
}

export const MeetingTopicCardEditable: React.FC<IMeetingTopicCardEditableProps> = (props) => {
  const {
    topic,
    order,
    isEditable,
    handleSetTopicEditable,
    users,
    participants,
    isMeetingStarted = true,
    onSubmitSuccess,
    sx
  } = props;

  const { title, assignedTo, durationSeconds, type, visibleBy, description } = topic;

  const { onSubmit, deleteTopic } = useTopic({
    topicId: topic.id,
    meetingId: topic.meetingId,
    isMeetingStarted,
    onSubmitSuccess
  });

  const { t, locale } = useLocale();
  const keyPrefix = 'Components.TopicCard';

  const isSmartType = checkIsSmartTopicType(topic.type);
  const isGenericType = checkIsGenericTopicType(topic.type);

  const visibleByObject = visibleBy?.reduce((acc: { [k: string]: boolean }, id, i) => {
    acc[id] = true;
    return acc;
  }, {});

  const defaultValues = {
    // ...topic
    title,
    assignedTo,
    durationSeconds,
    type,
    description,
    visibleBy: visibleByObject
  };

  const handleDeleteTopic = useCallback(async () => {
    try {
      await deleteTopic();

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }

      return true;
    } catch (error) {
      return false;
    }
  }, [topic.id]);

  // workaround for error message
  const { errorMessage } = useResourceShow({
    path: `/meetings`,
    id: topic.meetingId,
    useCache: true
  });

  const [isBodyFocused, setIsBodyFocused] = useState(false);

  const handleBodyFocus = useCallback(() => {
    setIsBodyFocused(true);
  }, []);

  const handleBodyBlur = useCallback((value: string) => {
    setIsBodyFocused(false);
  }, []);

  const isInCamera = checkIsInCameraTopicType(topic.type);

  // hide incamera edit action on agenda approval page
  const userHasEditAccess = isInCamera && isMeetingStarted ? false : true;

  const isEditableOrHasDocuments = isEditable ? true : topic.documentsIds && topic.documentsIds.length > 0;

  const renderEditSaveButton = (props?: { sx: SystemStyleObject }): React.ReactNode => {
    const { sx } = props || {};
    return (
      <Stack direction="row" sx={sx}>
        {userHasEditAccess && (
          <>
            {isEditable && (
              <>
                {topic.type !== 'quorum' && (
                  <Tooltip title={t('common:labelDelete')}>
                    <IconButton
                      onClick={handleDeleteTopic}
                      data-cy="meeting-prepare_topic-card_delete-button"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ ml: 2 }}
                  data-cy="meeting-prepare_topic-card_save-button"
                >
                  {t('common:labelSave')}
                </Button>
              </>
            )}

            {!isEditable && (
              <Tooltip title={t('common:labelEdit')}>
                <IconButton
                  onClick={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                  data-cy="meeting-prepare_topic-card_edit-button"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Stack>
    );
  };

  const renderTimestamp = () => {
    return (
      <TimestampChip
        timestampIso={
          topic.calculatedTopicStartTime
            ? DateTime.fromSeconds(topic.calculatedTopicStartTime).toISO()
            : undefined
        }
        sx={{ backgroundColor: 'customGrey.lighter' }}
      />
    );
  };

  return (
    <Box
      sx={{ minWidth: '0px', ...sx }}
      data-cy="meeting-prepare_topic-card"
      className={`meeting-prepare_topic-card-${topic.type}${isEditable ? ' is-editable' : ''}`}
    >
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>{renderTimestamp()}</Box>

        <Stack sx={{ minWidth: 0, flex: 1, ml: { md: 3 } }}>
          <FormControllerTemp
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            onSubmitSuccessResetType="FORM_PAYLOAD"
            sx={{ display: 'flex', flexDirection: 'column', gap: 1, minWidth: 0, flex: 1 }}
          >
            {/* mobile timestamp / save */}
            <Stack direction="row" justifyContent="space-between" sx={{ display: { md: 'none' } }}>
              {renderTimestamp()}
              <Box>{renderEditSaveButton()}</Box>
            </Stack>

            <Box
              sx={{
                display: { xs: 'block', lg: 'flex' },
                alignItems: { lg: 'flex-start' }
              }}
            >
              <Stack direction="column" width="100%" sx={{ minWidth: 0 }}>
                <Stack direction={{ md: 'row' }} gap={1} alignItems={{ md: isEditable ? null : 'center' }}>
                  {/* ORDER & TITLE */}
                  {isEditable && isGenericType ? (
                    <TextField
                      i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
                      name="title"
                      validationRules={{ maxLength: 150 }}
                      sx={{ width: '100%', mt: { md: 0 } }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{order}.</InputAdornment>
                      }}
                    />
                  ) : (
                    <Stack
                      direction="row"
                      sx={{
                        mr: { md: 'auto' },
                        minWidth: 0
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '700',
                          display: 'inline',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                        data-cy="meeting-prepare_topic-card_title"
                      >
                        {order}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
                      </Typography>
                      {isSmartType && (
                        <Stack sx={{ ml: 1, justifyContent: 'center' }}>
                          <SmartTopicLabel gradientId="boltIconGradientTop" />
                        </Stack>
                      )}
                    </Stack>
                  )}
                  {!isEditable && (
                    <Stack direction="row" gap={2} flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
                      {topic.assignedTo && (
                        <User
                          variant="avatar-tooltip-only"
                          sxAvatar={{
                            width: '32px',
                            height: '32px',
                            border: '1px solid',
                            borderColor: 'customGrey.light'
                          }}
                          id={topic?.assignedTo || ''}
                        />
                      )}
                      {topic.durationSeconds && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{
                            minWidth: '115px' // 2.5 minutes (lengthiest time) = 115px
                          }}
                        >
                          <TimerOutlinedIcon color="action" />
                          <Typography sx={{ width: '100%', opacity: 0.6, ml: 1, textWrap: 'nowrap' }}>
                            <>{topic.durationSeconds / 60 + ' minutes'}</>
                          </Typography>
                        </Stack>
                      )}
                      <Box
                        sx={{
                          minWidth: { md: '92px' } // information (longest chip) = 92px
                        }}
                      >
                        <TopicChipConditions topicType={topic.type} />
                      </Box>
                    </Stack>
                  )}
                  {/* EDIT/SAVE BUTTON DESKTOP */}
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>{renderEditSaveButton()}</Box>
                </Stack>

                {userHasEditAccess && isEditable && <ErrorMessage error={errorMessage} />}

                {/* OWNER, DURATION AND TYPE FIELDS */}
                {isEditable && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexGrow={1}
                    flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                    gap={{ xs: 1, md: 2 }}
                    sx={{ mt: { xs: 1, md: 0 } }}
                  >
                    {/* OWNER */}
                    <Stack
                      flexBasis={{ xs: '100%', sm: 'calc(50% - 8px)', lg: '33%' }}
                      sx={{
                        minWidth: '0px'
                      }}
                    >
                      <Form.Select
                        name="assignedTo"
                        i18n={{ keyPrefix: keyPrefix + '.FieldOwner' }}
                        options={
                          participants && participants?.length > 0
                            ? participants.map((user) => {
                                return {
                                  id: user.id,
                                  label: user.username as string
                                };
                              })
                            : users?.map((user) => {
                                return {
                                  id: user?.id,
                                  label: user?.username as string
                                };
                              })
                        }
                      />
                    </Stack>

                    {/* DURATION */}
                    <Stack flexBasis={{ xs: '100%', sm: 'calc(50% - 8px)', lg: '33%' }}>
                      <Form.Select
                        name="durationSeconds"
                        i18n={{ keyPrefix: keyPrefix + '.FieldDuration' }}
                        options={[
                          {
                            id: '150',
                            label: '2.5 minutes'
                          },
                          ...durations.map((duration) => {
                            return {
                              id: duration.toString(),
                              label: calculateDuration(t, duration * 1000)
                            };
                          })
                        ]}
                      />
                    </Stack>

                    {/* TOPIC TYPE */}
                    <Stack
                      direction="row"
                      flexBasis={
                        !isEditable
                          ? { xs: '50%', md: '25%' }
                          : { xs: '100%', sm: 'calc(50% - 8px)', lg: '33%' }
                      }
                    >
                      <>
                        {/* for a generic topic, in edit mode we show an input */}
                        {isGenericType ? (
                          <Form.Select
                            name="type"
                            i18n={{ keyPrefix: keyPrefix + '.FieldType' }}
                            options={[
                              {
                                id: 'information',
                                label: t(`common:topicTypes.information`)
                              },
                              {
                                id: 'resolution',
                                label: t(`common:topicTypes.resolution`)
                              },
                              {
                                id: 'discussion',
                                label: t(`common:topicTypes.discussion`)
                              }
                            ]}
                          />
                        ) : (
                          <TopicChipConditions topicType={topic.type} />
                        )}
                      </>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Box>
            {/* CEO REPORT TIP */}
            {topic.type === 'ceoReport' && isEditable && (
              <Alert
                sx={{
                  p: 0.5,
                  width: 'fit-content',
                  backgroundColor: 'transparent',
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
                icon={false}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      backgroundColor: 'tertiary.lighter',
                      borderRadius: '50%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LightbulbIcon
                      sx={{
                        color: 'tertiary.main',
                        stroke: `${theme.palette.primary.main}`,
                        strokeWidth: 1
                      }}
                    />
                  </Box>
                  <Typography>{t(keyPrefix + '.ceoReportTip')}</Typography>
                </Stack>
              </Alert>
            )}
            {/* IN CAMERA */}
            {isInCamera && (
              <InCameraField
                userHasEditAccess={userHasEditAccess}
                isEditable={isEditable}
                topic={topic}
                users={users}
                handleSetIsEditable={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                activeCommitteeMembers={props.activeCommitteeMembers}
              />
            )}
            {/* BODY FIELD */}
            {[
              'quorum',
              'nominations',
              'agendaApproval',
              'pastMinutesApproval',
              'ceoReport',
              'information',
              'discussion',
              'resolution',
              'miscellaneous',
              'adjournment'
            ].includes(topic.type) && (
              <>
                {isEditable ? (
                  <Box>
                    <RichTextMultiLine
                      name="description"
                      validationRules={{ maxLength: 20000 }}
                      isOptional={true}
                      onFocus={handleBodyFocus}
                      onBlur={handleBodyBlur}
                      height="unset"
                      minHeight={
                        topic.type === 'pastMinutesApproval' && !isBodyFocused
                          ? `${1 * 23 + 16.5 * 2}px`
                          : `${4 * 23 + 16.5 * 2}px`
                      } // (rows * 23px) + (padding * 2)
                      i18n={{ keyPrefix: keyPrefix + '.RichTextMultiLineDetails' }}
                    />
                  </Box>
                ) : (
                  topic.description && (
                    <RichTextReadOnly
                      value={topic.description}
                      sxEditorContainer={{
                        ...theme.typography.body1
                      }}
                    />
                  )
                )}
              </>
            )}
            {/* DOCUMENTS LIST */}
            {[
              'pastMinutesApproval',
              'ceoReport',
              'information',
              'discussion',
              'resolution',
              'miscellaneous'
            ].includes(topic.type) &&
              isEditableOrHasDocuments && (
                <DocumentList
                  path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
                  isEditable={isEditable}
                />
              )}
          </FormControllerTemp>

          {/* SELECT PAST MINUTES APPROVAL */}
          {topic.type === 'pastMinutesApproval' && (
            <PastMeetingsToApproveList
              meetingId={topic.meetingId}
              topicId={topic.id}
              isEditable={isEditable}
              topic={topic}
            />
          )}
          {topic.type === 'pastMinutesApproval' && userHasEditAccess && (
            <PastMinutesApprovalFieldFormController topic={topic} />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
