import { List } from '@mui/material';
import { Typography, Box, ListItem } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { Ref, useLocale } from '@koopajs/react';
import { IMeetingRef } from 'types';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { theme } from 'components/Core/theme';

interface IApprovedMinutesListProps {
  approvedMinutes?: { isApproved: boolean; meetingId: string }[];
}

export const ApprovedMinutesList: React.FC<IApprovedMinutesListProps> = (props) => {
  const { approvedMinutes } = props;

  const { t, locale } = useLocale();

  const filteredApprovedMinutes = approvedMinutes?.filter((m) => m.isApproved);

  return (
    <>
      {filteredApprovedMinutes && filteredApprovedMinutes.length > 0 && (
        <Box>
          <Typography
            sx={{
              opacity: 0.8,
              mt: 2,
              '@media print': {
                mt: 0,
                opacity: 1,
                ...theme.typography.body2
              }
            }}
          >
            {t('Components.ApprovedMinutesList.pastMinutesAdopted', {
              count: filteredApprovedMinutes.length
            })}
            :
          </Typography>
          <List
            sx={{
              mt: '0px!important',
              pt: 0.5,
              '@media print': {
                listStyle: 'disc',
                pl: 3,
                pb: 0
              }
            }}
          >
            {filteredApprovedMinutes.map((meeting) => {
              return (
                <ListItem
                  disableGutters
                  key={meeting.meetingId}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pb: 0.5,
                    pt: 0,
                    '@media print': {
                      display: 'list-item',
                      '&:last-child': {
                        pb: 0
                      }
                    }
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <AccessTime
                      sx={{
                        mr: '8px',
                        '@media print': {
                          display: 'none'
                        }
                      }}
                    />

                    <Ref content={`ref:meeting:${meeting.meetingId}`}>
                      {(item) => {
                        const minutes = item as IMeetingRef;
                        const committeeType = useCommitteeName(minutes?.committeeId || '');
                        return (
                          <>
                            <Typography
                              sx={{
                                '@media print': {
                                  ...theme.typography.body2
                                }
                              }}
                            >
                              {minutes?.startDateTime &&
                                formatDate({
                                  isoString: minutes.startDateTime,
                                  format: 'DATE_FULL',
                                  locale
                                })}
                              {' | '}
                              {committeeType}
                              {' | '}
                              {minutes?.label}
                            </Typography>
                          </>
                        );
                      }}
                    </Ref>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </>
  );
};
