import { Box, Typography } from '@mui/material';
import { IResolution } from 'types';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { useLocale, useWorkspaceShow } from '@koopajs/react';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';

interface IWrittenResolutionDialogDetailsCardProps {
  resolution: IResolution;
}

export const WrittenResolutionDialogDetailsCard: React.FC<IWrittenResolutionDialogDetailsCardProps> = (
  props
) => {
  const { resolution } = props;

  const { locale, t } = useLocale();

  const title = resolution?.attachedToEmail ? t('common:writtenResolution') : resolution.title;

  const { workspace } = useWorkspaceShow();

  const committeeName = useCommitteeName(resolution.committeeId);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant="overline"
        data-cy="view-resolution-dialog_resolution-number"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0px', whiteSpace: 'nowrap' }}
      >
        {title} {resolution.resolutionNumber && `#${resolution.resolutionNumber}`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 0, sm: 1 },
          flexWrap: 'wrap',
          mb: '4px'
        }}
      >
        <Typography variant="h6">
          <DateTimeStartEnd isoStringDate={resolution.sealedAt} dateFormat="DATE_HUGE" />
        </Typography>{' '}
        <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
          {' | '}
        </Typography>
        <Typography variant="h6"> {committeeName}</Typography>
      </Box>
      <Typography variant="body2">{workspace?.name}</Typography>
    </Box>
  );
};
