import { Chip } from '@mui/material';
import { formatTime } from '../utils/DateTime/formatTime';
import { useLocale } from '@koopajs/react';
import { TimerOutlined as TimerOutlinedIcon } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface ITimestampChipProps {
  timestampIso?: string;
  sx?: SxProps<Theme>;
  variant?: 'filled' | 'outlined';
}

export const TimestampChip: React.FC<ITimestampChipProps> = (props) => {
  const { timestampIso, sx, variant } = props;

  const { locale } = useLocale();

  return (
    <Chip
      label={
        timestampIso ? (
          formatTime({
            isoString: timestampIso,
            locale
          })
        ) : (
          <TimerOutlinedIcon sx={{ fontSize: '16px' }} />
        )
      }
      sx={{
        ...(timestampIso
          ? { fontVariantNumeric: 'tabular-nums' }
          : {
              '& .MuiChip-label': {
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '50px'
              }
            }),
        ...sx
      }}
      variant={variant}
    />
  );
};
