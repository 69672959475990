import React, { useCallback } from 'react';
import { useUserShow, useUserUpdate, FormController } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { Button, Box, Stack } from '@mui/material';
import { ICoreProps, ErrorMessage } from '@koopajs/mui';
import { useLocale } from '@koopajs/react';
import { RadioFieldTemp } from 'components/RadioFieldTemp';

export interface IContentLanguageProps extends ICoreProps {
  moveNext: () => void;
}

export const ContentLanguage: React.FC<IContentLanguageProps> = (props: IContentLanguageProps) => {
  const { moveNext, i18n } = props;

  const { t, setLocale } = useLocale();
  const keyPrefix = i18n?.keyPrefix;

  const { user, isProcessing: isProcessingUserShow, errorMessage: errorMessageUserShow } = useUserShow();
  const {
    updateUser,
    isProcessing: isProcessingUserUpdate,
    errorMessage: errorMessageUserUpdate
  } = useUserUpdate();

  const onSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const formDataTyped = formData as { locale: string };
    const isSuccessful = await updateUser({ ...formDataTyped });

    if (isSuccessful) {
      await setLocale(formDataTyped.locale);

      moveNext();
      TrackEvent('onboarding-language');
    }

    return isSuccessful;
  }, []);

  const localeLabels: { [k: string]: string } = {
    'en-US': 'English (US)',
    'fr-CA': 'Français (CA)'
  };

  return (
    <Box>
      <ErrorMessage error={errorMessageUserShow} />
      <ErrorMessage error={errorMessageUserUpdate} />

      <Box>
        <Box>
          <Box className={'style.inputWrapper'} sx={{ mt: 1.25, mb: 1.25 }}>
            <FormController
              onSubmit={onSubmit}
              defaultValues={{
                locale: user?.locale || ''
              }}
            >
              <RadioFieldTemp
                name="locale"
                i18n={{ keyPrefix: `${keyPrefix}.RadioForm` }}
                options={[
                  { id: 'en-US', label: localeLabels['en-US'] },
                  { id: 'fr-CA', label: localeLabels['fr-CA'] }
                ]}
              />

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isProcessingUserShow || isProcessingUserUpdate}
                  type="submit"
                >
                  {t('common:labelNext')}
                </Button>
              </Stack>
            </FormController>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
