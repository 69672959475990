import { AllTopicType, checkIsGenericTopicType } from 'utils/topicTypeArrays';
import { TopicChip } from './TopicChip';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface ITopicChipConditionsProps {
  topicType: AllTopicType;
  isOutlined?: boolean;
  isPrintView?: boolean;
  sx?: SxProps<Theme>;
}

export const TopicChipConditions: React.FC<ITopicChipConditionsProps> = (props) => {
  const { topicType, isOutlined, isPrintView, sx } = props;

  const extraProps = {
    isOutlined,
    isPrintView,
    sx
  };

  if (checkIsGenericTopicType(topicType)) {
    return <TopicChip topicType={topicType} {...extraProps} />;
  } else if (topicType === 'ceoReport') {
    return <TopicChip topicType="information" {...extraProps} />;
  } else if (['nominations', 'agendaApproval', 'pastMinutesApproval', 'adjournment'].includes(topicType)) {
    return <TopicChip topicType="resolution" {...extraProps} />;
  } else {
    return null;
  }
};
