import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserShow, useWorkspaceShow, useComponentVisibility, useLocale } from '@koopajs/react';
import { TopBarUser } from '@koopajs/mui/dist/components/App/TopBarUser';
import { Logo } from '../Logo';
import { Toolbar, Box, IconButton, Typography, Alert, Link as MuiLink, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useScreenSize } from 'utils/useScreenSize';
import { useTheme } from '@mui/material/styles';
import PanoramaLogo from '../../../assets/panorama-logo.png';
import LockClockIcon from '@mui/icons-material/LockClock';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useCheckIfHidingNavAndSidebar } from 'components/hooks/useCheckIfHidingNavAndSidebar';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

export const Header: React.FC = () => {
  const isMainNavAndSidebarHidden = useCheckIfHidingNavAndSidebar();

  const { t } = useLocale();
  const keyPrefix = 'Header';

  const { user } = useUserShow();
  const { workspace } = useWorkspaceShow();
  const theme = useTheme();
  const navMenu = useComponentVisibility('navMenu');
  const userMenu = useComponentVisibility('userMenu');

  const { isScreenAboveMd } = useScreenSize();

  const usernames: [string] | [string, string] = [`${user?.firstname} ${user?.lastname}`];
  if (workspace?.name) {
    usernames.push(workspace.name);
  }

  return (
    <>
      <Toolbar
        sx={{
          display: isMainNavAndSidebarHidden ? 'none' : 'flex',
          height: '64px',
          minHeight: '64px',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.customGrey.light}`,
          backgroundColor: '#FFFFFF'
        }}
      >
        <Box sx={{ flex: { xs: 1, lg: 'inherit' }, display: { xs: 'flex', lg: 'block' } }}>
          <IconButton
            onClick={navMenu.setVisible}
            aria-label={t('common:menu')}
            sx={{ display: { lg: 'none' } }}
            data-cy="hamburger-menu-button"
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: { lg: `1px solid ${theme.palette.customGrey.light}` },
            pr: { lg: '24px' },
            height: '100%',
            width: { lg: `${230 - 24}px` }, // nav sidebar width - nav padding
            minWidth: { lg: `${230 - 24}px` }
          }}
        >
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Logo isSmallVersion={!isScreenAboveMd} sx={{ maxWidth: { xs: '200px', lg: '100%' } }} />
          </Link>
        </Box>
        <Box sx={{ px: '24px', display: { xs: 'none', lg: 'block' } }}>
          <Typography sx={{ fontSize: 12, opacity: 0.4, mb: 0.5 }}> {t(keyPrefix + '.poweredBy')}</Typography>
          <Box
            component="img"
            src={PanoramaLogo}
            sx={{ display: 'block', maxWidth: '108px', maxHeight: '20px' }}
          />
        </Box>
        <Alert
          sx={{
            display: { xs: 'none', lg: 'flex' },
            mx: 'auto',
            p: '8px',
            '& .MuiAlert-message, .MuiAlert-icon': { p: 0 },
            backgroundColor: 'transparent',
            color: 'primary.main'
          }}
          icon={<VolunteerActivismIcon sx={{ fontSize: '18px', color: 'primary.main' }} />}
        >
          <Box>
            {t(keyPrefix + '.bannerMessage')}
            <MuiLink
              href={t(keyPrefix + '.bannerCtaUrl')}
              sx={{
                alignItems: 'center',
                textDecoration: 'none',
                color: 'link.main'
              }}
              target="_blank"
              rel="noreferrer"
            >
              {t(keyPrefix + '.bannerCtaMessage')}{' '}
              <OpenInNewIcon fontSize="small" sx={{ fontSize: '18px', verticalAlign: 'text-bottom' }} />
            </MuiLink>
          </Box>
        </Alert>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{ mx: 4, display: { xs: 'none', lg: 'flex', color: '#000000', opacity: 0.6 } }}
        >
          <LockClockIcon />{' '}
          <Typography variant="body2" component="span" sx={{ ml: 1, maxWidth: 'min-content' }}>
            {t(keyPrefix + '.secureSession')}
          </Typography>
        </Stack>
        <Box
          sx={{
            flex: { xs: 1, lg: 'inherit' },
            display: { xs: 'flex', lg: 'block' },
            justifyContent: 'flex-end',
            // TODO: Remove maxWidth when we will change the navbar to the left!
            maxWidth: { xs: 'none', lg: '240px' },
            '& .MuiAvatar-root': {
              backgroundColor: 'customGrey.light',
              color: '#FFFFFF'
            }
          }}
          data-cy="top-bar-user-box"
        >
          <TopBarUser
            usernames={isScreenAboveMd ? usernames : undefined}
            avatarSrc={user?.profilePicture}
            avatarText={
              (user?.firstname ? user?.firstname?.charAt(0) : '') +
              (user?.lastname ? user?.lastname?.charAt(0) : '')
            }
            // workspaceAvatar={workspace?.icon}
            setMenuUserVisible={userMenu.setVisible}
          />
        </Box>
      </Toolbar>
    </>
  );
};
