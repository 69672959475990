import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { IDocument } from 'types';
import { SystemStyleObject } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { useComponentVisibility } from '@koopajs/react';

interface IBoardMemberViewTopicCardDocumentButton {
  document: IDocument;
  sxContainer?: SystemStyleObject;
  buttonActionProps?: ButtonProps;
}

export const BoardMemberViewTopicCardDocumentButton: React.FC<IBoardMemberViewTopicCardDocumentButton> = (
  props
) => {
  const { document, sxContainer, buttonActionProps } = props;

  const additionalButtonProps = buttonActionProps
    ? buttonActionProps
    : {
        target: '_blank',
        rel: 'noopener',
        href: `/documents/${document.id}`,
        component: 'a'
      };

  return (
    <Box
      // ref={targetRef}
      sx={{
        ...sxContainer
      }}
      data-cy="board-member-view_document-view-button-container"
      // this is needed for scroll into view from email
      id={document.id}
      data-topic-id={document.topicId}
    >
      <Button
        sx={{
          textTransform: 'none',
          color: 'link.main',
          p: 0.2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%'
        }}
        data-cy="board-member-view_document-view-button"
        {...additionalButtonProps}
      >
        <RenderDocumentIcon fileType={document.attachedFile.format} sx={{ opacity: 1 }} />

        <Box
          sx={{
            ml: 1,
            maxWidth: '500px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '16px'
          }}
        >
          {document?.attachedFile?.name || document?.attachedFile?.uri}
        </Box>
      </Button>
    </Box>
  );
};
