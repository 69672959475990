import { useLocale, User } from '@koopajs/react';
import { List, ListItem, Typography, Box, Avatar } from '@mui/material';
import { CommitteeMemberTitle } from 'types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IParticipantsWithTitlesAccordionListProps {
  userIds: string[];
  label: string;
  committeeMembersTitles?: { [k: string]: CommitteeMemberTitle };
}

export const ParticipantsWithTitlesAccordionList: React.FC<IParticipantsWithTitlesAccordionListProps> = (
  props
) => {
  const { userIds, label, committeeMembersTitles } = props;

  const { t } = useLocale();

  if (!userIds?.length) {
    return null;
  }

  return (
    <>
      <Accordion
        elevation={0}
        sx={{
          borderTop: 'none',
          marginTop: '0!important',
          '&:before': {
            display: 'none'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="meeting-participant-list"
          id="panel1-header"
          sx={{
            p: 0,
            width: 'fit-content',
            minHeight: '0px!important',
            '& .MuiAccordionSummary-content': { m: '0px!important' }
          }}
        >
          <Typography variant="caption">{label}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List
            sx={{
              p: 0,
              display: 'grid',
              alignItems: 'flex-start',
              gridTemplateColumns: {
                xs: '1fr',
                sm: '1fr 1fr 1fr',
                md: '1fr 1fr 1fr 1fr',
                lg: '1fr 1fr 1fr 1fr 1fr'
              }
            }}
          >
            {userIds?.map((userId) => {
              const title = committeeMembersTitles?.[userId];

              return (
                <ListItem disableGutters key={userId} sx={{ pr: 2 }}>
                  <User id={userId}>
                    {(user) => {
                      return (
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <Avatar
                            alt={user?.username}
                            src={user?.profilePicture}
                            sx={{
                              width: '24px',
                              height: '24px'
                            }}
                          />
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
                              {user?.username}
                            </Typography>{' '}
                            {title && (
                              <Typography variant="body2" sx={{ opacity: '0.6', whiteSpace: 'normal' }}>
                                {t(`common:committeeMemberTitles.${title}`)}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      );
                    }}
                  </User>
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
