import React from 'react';
import { useLocale } from '@koopajs/react';
import { Box, Typography, Stack } from '@mui/material';
import { ITopic } from '../../../types';
import { DateTime } from 'luxon';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { DocumentList } from 'components/DocumentList';
import { PastMeetingsToApproveList } from '../../TopicCard/PastMinutesApprovalField/PastMeetingsToApproveList';
import { User } from '@koopajs/mui';
import { TopicChipConditions } from 'components/TopicChipConditions';
import { TimestampChip } from 'components/TimestampChip';
import { theme } from 'components/Core/theme';

interface IMeetingViewTopicCardProps {
  topic: ITopic;
  order: number;
  showApprovedPastMeetingsToApprove?: boolean;
}

export const MeetingViewTopicCard: React.FC<IMeetingViewTopicCardProps> = (props) => {
  const { topic, order, showApprovedPastMeetingsToApprove } = props;

  const { t } = useLocale();
  const hasDocuments = topic.documentsIds && topic.documentsIds.length > 0;

  const renderTimestampChip = (): React.ReactElement => {
    return (
      <TimestampChip
        timestampIso={
          topic.calculatedTopicStartTime
            ? DateTime.fromSeconds(topic.calculatedTopicStartTime).toISO()
            : undefined
        }
        sx={{ xs: 'auto', lg: 3, backgroundColor: 'customGrey.lighter' }}
      />
    );
  };

  return (
    <Box data-cy="meeting-view_topic-card" className={`meeting-view_topic-card-${topic.type}`}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: { xs: 2, md: 3 } }}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>{renderTimestampChip()}</Box>
        <Stack direction="column" gap={1} flex={1} sx={{ minWidth: 0 }}>
          <Stack
            direction={{ md: 'row' }}
            gap={{ xs: 2, md: 3 }}
            alignItems={{ md: 'center' }}
            sx={{
              marginBottom: -0.5 // brings title closer to content below
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                mr: 'auto',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {order}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
            </Typography>
            <Stack
              direction="row"
              gap={{ xs: 2, md: 3 }}
              flexWrap={{ xs: 'wrap', md: 'nowrap' }}
              flexShrink={0}
            >
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>{renderTimestampChip()}</Box>
              {topic.assignedTo && (
                <User
                  variant="avatar-tooltip-only"
                  sxAvatar={{
                    width: '32px',
                    height: '32px',
                    border: '1px solid',
                    borderColor: 'customGrey.light'
                  }}
                  id={topic?.assignedTo || ''}
                />
              )}
              <Stack
                direction="row"
                justifyContent={{ md: 'flex-start' }}
                sx={{
                  minWidth: { md: '92px' } // information (longest chip) = 92px
                }}
              >
                <TopicChipConditions topicType={topic.type} />
              </Stack>
            </Stack>
          </Stack>
          {/* BODY FIELD */}
          {[
            'quorum',
            'nominations',
            'agendaApproval',
            'pastMinutesApproval',
            'ceoReport',
            'information',
            'discussion',
            'resolution',
            'miscellaneous',
            'adjournment'
          ].includes(topic.type) &&
            topic.description && (
              <RichTextReadOnly
                value={topic.description}
                sxEditorContainer={{
                  ...theme.typography.body1
                }}
              />
            )}
          {/* DOCUMENTS LIST */}
          {[
            'pastMinutesApproval',
            'ceoReport',
            'information',
            'discussion',
            'resolution',
            'miscellaneous'
          ].includes(topic.type) &&
            hasDocuments && (
              <DocumentList path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`} />
            )}{' '}
          {/* SELECT PAST MINUTES APPROVAL */}
          {topic.type === 'pastMinutesApproval' && (
            <PastMeetingsToApproveList
              meetingId={topic.meetingId}
              topicId={topic.id}
              topic={topic}
              showApprovedMeetings={showApprovedPastMeetingsToApprove ? true : false}
              sxTitle={{ mt: 0 }}
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};
