import { Dialog, Loading } from '@koopajs/mui';
import { IApprovedTopic } from 'types';
import { Box, Typography, Paper, Divider } from '@mui/material';
import { useLocale, useResourceShow } from '@koopajs/react';
import { DocumentList } from '../DocumentList';
import { UserList } from 'components/UserList';
import { Lock as LockIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { InCameraMeetingHeaderCard } from 'components/InCamera/InCameraMeetingHeaderCard';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useEffect } from 'react';
import { extractErrorMessage } from 'utils/extractErrorMessage';

interface IViewInCameraProps {
  inCameraId?: string;
}

export const ViewInCamera: React.FC<IViewInCameraProps> = (props) => {
  const { inCameraId } = props;
  const history = useHistory();
  const {
    resource: inCamera,
    isProcessing,
    errorMessage
  } = useResourceShow<IApprovedTopic>({
    path: '/approved-topics',
    id: inCameraId
  });
  const keyPrefix = 'InCamera.InCameraCard';
  const { t } = useLocale();

  const handleOnClose = useCallback(() => {
    history.push({ pathname: `/in-camera`, search: history.location?.search });
  }, []);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const extractedErrorMessage = extractErrorMessage(errorMessage);

  useEffect(() => {
    if (errorMessage === "Error 403: You don't have access to this topic") {
      enqueueSnackbar(extractedErrorMessage, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      history.push('/in-camera');
    }
  }, [errorMessage, enqueueSnackbar, closeSnackbar, inCameraId]);
  return (
    <Dialog.View
      isOpen={Boolean(inCameraId && inCamera)}
      onClose={handleOnClose}
      isCloseVisible={true}
      i18n={{ keyPrefix: 'InCamera.DialogView' }}
      dialogProps={{ className: 'rr-block' }}
    >
      {isProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        <Box>
          {inCamera && <InCameraMeetingHeaderCard approvedTopic={inCamera} />}

          <Paper sx={{ p: 3, my: 2 }} variant="outlined">
            {inCamera?.visibleBy && inCamera.visibleBy?.length > 0 && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LockIcon sx={{ opacity: '0.6', mr: 1 }} />
                  <Typography variant="caption" sx={{ opacity: '0.6' }}>
                    {t(keyPrefix + '.authorizedPeople', { count: inCamera?.visibleBy?.length })}
                  </Typography>
                </Box>{' '}
                <Box>
                  <UserList userIds={inCamera?.visibleBy} />
                </Box>
              </>
            )}
            {inCamera?.notes && (
              <>
                <Divider />
                <Typography variant="body2" component="div" sx={{ my: 2 }}>
                  <RichTextReadOnly value={inCamera.notes} />
                </Typography>
              </>
            )}
            {inCamera?.meetingId && Boolean(inCamera?.documentsIds?.length) && (
              <>
                <DocumentList
                  path={`/approved-topics/${inCamera?.id}/documents`}
                  isAccordion={true}
                  accordionOptions={{
                    defaultExpanded: true,
                    documentsVisible: 0
                  }}
                />
              </>
            )}
          </Paper>
        </Box>
      )}
    </Dialog.View>
  );
};
