import { ITopic } from 'types';
import { BoardMemberViewTopicCardAccordion } from './Components/Accordion';
import { BoardMemberViewTopicCardAccordionSummaryChildren } from './Components/AccordionSummaryChildren';
import { DocumentList } from './Components/DocumentList';
import { useDocumentCount } from 'components/hooks/BoardMemberView/useDocumentCount';
import { TopicDescription } from './Components/TopicDescription';

interface IBoardMemberViewBasicTopicCard {
  topic: ITopic;
  index: number;
}

export const BoardMemberViewBasicTopicCard: React.FC<IBoardMemberViewBasicTopicCard> = (props) => {
  const { topic, index } = props;

  const {
    linkDocumentsCount,
    setLinkDocumentsCount,
    fileDocumentsCount,
    setFileDocumentsCount,
    hasDocuments
  } = useDocumentCount({ topic });

  const hasContent = Boolean(topic.description || hasDocuments);

  return (
    <BoardMemberViewTopicCardAccordion
      topic={topic}
      index={index}
      isDisabled={!hasContent}
      accordionSummary={
        <BoardMemberViewTopicCardAccordionSummaryChildren
          topic={topic}
          index={index}
          linkDocumentsCount={linkDocumentsCount}
          fileDocumentsCount={fileDocumentsCount}
        />
      }
      accordionDetails={
        <>
          {topic.description && <TopicDescription description={topic.description} />}

          {hasDocuments && (
            <DocumentList
              path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
              setLinkDocumentsCount={setLinkDocumentsCount}
              setFileDocumentsCount={setFileDocumentsCount}
            />
          )}
        </>
      }
    />
  );
};
