import { useComponentVisibility, useResourceList } from '@koopajs/react';
import { Box, Button, Stack } from '@mui/material';
import { IDocument } from 'types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { BoardMemberViewTopicCardDocumentButton } from './DocumentButton';
import { DocumentAnnotation } from '../../../../DocumentAnnotation';
import { SystemStyleObject } from '@mui/system';
import { useBoardMemberViewContext } from 'components/BoardMemberView/BoardMemberViewProvider';
import { useLocation } from 'react-router-dom';
import { useScreenSize } from 'utils/useScreenSize';

interface IDocumentListProps {
  path: string;
  setLinkDocumentsCount: Dispatch<SetStateAction<number>>;
  setFileDocumentsCount: Dispatch<SetStateAction<number>>;
  sxButtonContainer?: SystemStyleObject;
}

export const DocumentList: React.FC<IDocumentListProps> = (props) => {
  const { path, setLinkDocumentsCount, setFileDocumentsCount, sxButtonContainer } = props;

  const { resources: documents } = useResourceList<IDocument>({
    path
  });

  const boardMemberViewContext = useBoardMemberViewContext();

  const { pdfDocuments: meetingPdfDocuments } = boardMemberViewContext;

  const links: IDocument[] = [];
  const pdfDocuments: IDocument[] = [];
  const otherDocuments: IDocument[] = [];

  for (const document of documents) {
    if (document.attachedFile.format === 'url') {
      links.push(document);
    } else if (document.attachedFile.format === 'application/pdf') {
      pdfDocuments.push(document);
    } else {
      otherDocuments.push(document);
    }
  }

  useEffect(() => {
    setLinkDocumentsCount(links.length);
    setFileDocumentsCount(pdfDocuments.length + otherDocuments.length);
  }, [documents.length]);

  // this will probably move into context and actually do something but putting it here for now until we have it
  const isDesktopModeEnabled = false;

  const boardMemberViewDocumentDialog = useComponentVisibility('boardMemberViewDocumentDialog');
  const openDialog = useCallback(
    (documentId: string) => {
      return () => {
        boardMemberViewDocumentDialog.setVisibleWithContext({
          documentId,
          pdfDocuments: meetingPdfDocuments
        });
      };
    },
    [meetingPdfDocuments.length]
  );

  // open document dialog if the document is in the url hash
  const location = useLocation();
  const urlHash = location.hash.slice(1);
  const isHashUlid = /^[0-7][0-9A-HJKMNP-TV-Z]{25}$/i.test(urlHash);

  useEffect(() => {
    if (!meetingPdfDocuments || !urlHash || !isHashUlid) return;

    const documentIdFromHash = urlHash;

    const hasMatchingDocument = meetingPdfDocuments.some(
      (pdfDocument) => pdfDocument.id === documentIdFromHash
    );

    if (hasMatchingDocument) {
      openDialog(documentIdFromHash)();
    }
  }, [meetingPdfDocuments.length, urlHash]);

  const { isScreenAboveLg, isMobileScreen } = useScreenSize();

  // scroll to document if it's in the url hash
  useEffect(() => {
    if (!documents.length || !urlHash || !isHashUlid) return;

    const hasMatchingDocument = documents.some((document) => document.id === urlHash);

    if (!hasMatchingDocument) return;

    // delay the scroll to ensure DOM is ready
    setTimeout(() => {
      //desktop we scroll to the document
      const documentId = urlHash;
      const documentElement = document.getElementById(documentId);

      if (documentElement && isScreenAboveLg) {
        documentElement.scrollIntoView({ behavior: 'smooth' });
      }

      //mobile we scroll to the topic
      if (!isScreenAboveLg) {
        const topicId = documentElement?.dataset.topicId;
        const offset = isMobileScreen ? 55 : 63; // header height + 1px (so it's highlighted)
        const topicElement = document.getElementById(`topic-card-${topicId}`);
        const topicPosition = topicElement ? topicElement.getBoundingClientRect().top : 0;

        window.scrollTo({
          top: topicPosition - offset,
          behavior: 'smooth'
        });
      }
    }, 1000);
  }, [urlHash, documents.length]);

  if (!documents.length) {
    return null;
  }

  return (
    <Stack gap={1}>
      {links.map((document, index) => {
        return (
          <Box
            key={document.id}
            sx={{
              ...sxButtonContainer,
              scrollMarginTop: '50px' // adds space for topic card header when scrolling into view
            }}
            data-cy="board-member-view_document-link-button"
            id={document.id}
          >
            <Button
              sx={{
                textTransform: 'none',
                color: 'link.main',
                p: 0.2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
              }}
              component="a"
              target="_blank"
              rel="noopener"
              href={document.attachedFile.uri}
            >
              <OpenInNewIcon />

              <Box
                sx={{
                  ml: 1,
                  maxWidth: '500px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '16px'
                }}
              >
                {document?.attachedFile?.name || document?.attachedFile?.uri}
              </Box>
            </Button>
          </Box>
        );
      })}
      {otherDocuments.map((document, index) => {
        return (
          <BoardMemberViewTopicCardDocumentButton
            sxContainer={{
              ...sxButtonContainer,
              scrollMarginTop: '50px' // adds space for topic card header when scrolling into view
            }}
            key={document.id}
            document={document}
          />
        );
      })}
      {pdfDocuments.map((document, index) => {
        if (!isDesktopModeEnabled) {
          return (
            <BoardMemberViewTopicCardDocumentButton
              sxContainer={{
                ...sxButtonContainer,
                scrollMarginTop: '50px' // adds space for topic card header when scrolling into view
              }}
              key={document.id}
              document={document}
              buttonActionProps={{ onClick: openDialog(document.id) }}
            />
          );
        }
        return (
          <DocumentAnnotation
            allowAnnotate
            key={document.id}
            documentId={document.id}
            testId="document-annotation-iframe"
            sx={{ height: { xs: 'calc(100vh - 64px)', lg: '100vh' } }}
          />
        );
      })}
    </Stack>
  );
};
