import { IApprovedTopic, IResolution, ITopic } from 'types';
import { useLocale } from '@koopajs/react';
import { formatTime } from 'utils/DateTime/formatTime';
import Typography from '@mui/material/Typography';
import { theme } from 'components/Core/theme';

interface IResolutionOutcomeTextProps {
  topic: ITopic | IApprovedTopic | IResolution | undefined;
}

export const ResolutionOutcomeText: React.FC<IResolutionOutcomeTextProps> = (props) => {
  const { topic } = props;
  const { isUnanimous, outcome, mover, seconder, recordedAt } = topic?.resolutionOutcome || {};

  const { t, locale } = useLocale();
  const keyPrefix = 'Components.ResolutionOutcome';

  let outcomeTranslationKey: string | undefined = outcome;

  if (isUnanimous) {
    if (outcome === 'approved') outcomeTranslationKey = 'approvedUnanimously';
    if (outcome === 'approvedWithMods') outcomeTranslationKey = 'approvedUnanimouslyWithMods';
  }
  let timeText = '';
  if (topic?.version === 1) {
    timeText = t(`${keyPrefix}.timeText`, {
      time: formatTime({ isoString: recordedAt!, locale })
    });
  }
  const proposalOptions = {
    outcomeText: t(`${keyPrefix}.outcomeText.${outcomeTranslationKey}`),
    mover,
    seconder,
    timeText
  };

  return (
    <Typography
      variant="body1"
      color="initial"
      component="span"
      data-cy="resolution-outcome-text"
      sx={{
        '@media print': {
          ...theme.typography.body2
        }
      }}
    >
      {!mover && !seconder && t(keyPrefix + '.proposal', proposalOptions)}
      {mover && !seconder && t(keyPrefix + '.proposalMover', proposalOptions)}
      {!mover && seconder && t(keyPrefix + '.proposalSeconder', proposalOptions)}
      {mover && seconder && t(keyPrefix + '.proposalMoverSeconder', proposalOptions)}
    </Typography>
  );
};
