/* eslint-disable react/jsx-no-bind */
import {
  Button,
  ButtonGroup,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  Tooltip,
  DialogActions
} from '@mui/material';
import { Editor } from '@tiptap/react';
import React, { useCallback, useState } from 'react';
import {
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  StrikethroughS as StrikethroughSIcon,
  FormatClear as FormatClearIcon,
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  FormatQuote as FormatQuoteIcon,
  // LayersClear as LayersClearIcon,
  Undo as UndoIcon,
  Redo as RedoIcon
} from '@mui/icons-material';
import { TOptions, StringMap } from 'i18next';
import { useLocale } from '@koopajs/react';
import cc from 'classnames';

interface IRichTextMultiLineMenuBarProps {
  editor: Editor | null;
  isDisabled: boolean;
  i18n?: {
    keyPrefix?: string;
    options?: TOptions<StringMap>;
  };
  isError?: boolean;
}

export const RichTextMultiLineMenuBar: React.FC<IRichTextMultiLineMenuBarProps> = (props) => {
  const { editor, isDisabled, i18n, isError } = props;
  const { t } = useLocale();
  const keyPrefix = i18n?.keyPrefix;

  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [urlFieldValue, setUrlFieldValue] = useState('');
  const [isUrlFieldInvalid, setIsUrlFieldInvalid] = useState(false);

  const handleOpenLinkModal = () => {
    setUrlFieldValue(editor?.getAttributes('link').href || '');
    setIsLinkModalOpen(true);
  };

  const handleCloseLinkModal = () => {
    setIsLinkModalOpen(false);
    setUrlFieldValue('');
    setIsUrlFieldInvalid(false);
  };

  const setLink = useCallback(() => {
    let url = urlFieldValue;

    if (
      !url.match(
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
      )
    ) {
      setIsUrlFieldInvalid(true);
      return;
    }

    if (url === null) {
      // cancelled
      setIsLinkModalOpen(false);
      return;
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      setIsLinkModalOpen(false);
      return;
    }

    // adds http if needed
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = 'http://' + url;
    }

    // if there isn't any text highlighted set text to be url
    if (editor?.state.selection.empty) {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .command(({ tr }) => {
          tr.insertText(url);
          return true;
        })
        .run();
    } else {
      editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }

    setIsLinkModalOpen(false);
  }, [editor, urlFieldValue]);

  if (!editor) {
    return null;
  }

  const { isFocused } = editor;

  const generateSxButton = (isActive?: boolean) => {
    return {
      minWidth: '40px',
      px: 0,
      backgroundColor: isActive && isFocused ? 'secondary.lighter' : undefined,
      mr: 1
    };
  };

  const renderButtons = (): React.ReactNode => {
    return (
      <>
        <Tooltip title={t(keyPrefix + '.labelBold')}>
          <span>
            <Button
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run() || isDisabled}
              sx={generateSxButton(editor.isActive('bold'))}
              aria-label={t(keyPrefix + '.labelBold')}
            >
              <FormatBoldIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelItalic')}>
          <span>
            <Button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run() || isDisabled}
              sx={generateSxButton(editor.isActive('italic'))}
              aria-label={t(keyPrefix + '.labelItalic')}
            >
              <FormatItalicIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelStrikethrough')}>
          <span>
            <Button
              type="button"
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run() || isDisabled}
              sx={generateSxButton(editor.isActive('strike'))}
              aria-label={t(keyPrefix + '.labelStrikethrough')}
            >
              <StrikethroughSIcon />
            </Button>
          </span>
        </Tooltip>

        {/* links */}
        <Tooltip title={t(keyPrefix + '.labelAddLink')}>
          <span>
            <Button
              onClick={handleOpenLinkModal}
              sx={generateSxButton(editor.isActive('link'))}
              disabled={isDisabled}
              aria-label={t(keyPrefix + '.labelAddLink')}
            >
              <LinkIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelRemoveLink')}>
          <span>
            <Button
              onClick={() => editor.chain().focus().unsetLink().run()}
              disabled={!editor.isActive('link') || isDisabled}
              sx={generateSxButton()}
              aria-label={t(keyPrefix + '.labelRemoveLink')}
            >
              <LinkOffIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelBulletList')}>
          <span>
            <Button
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              sx={generateSxButton(editor.isActive('bulletList'))}
              disabled={isDisabled}
              aria-label={t(keyPrefix + '.labelBulletList')}
            >
              <FormatListBulletedIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelNumberedList')}>
          <span>
            <Button
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              sx={generateSxButton(editor.isActive('orderedList'))}
              disabled={isDisabled}
              aria-label={t(keyPrefix + '.labelNumberedList')}
            >
              <FormatListNumberedIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelQuote')}>
          <span>
            <Button
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              sx={generateSxButton(editor.isActive('blockquote'))}
              disabled={isDisabled}
              aria-label={t(keyPrefix + '.labelQuote')}
            >
              <FormatQuoteIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelClearFormatting')}>
          <span>
            <Button
              onClick={() => {
                editor.chain().focus().clearNodes().run();
                editor.chain().focus().unsetAllMarks().run();
              }}
              sx={generateSxButton()}
              disabled={isDisabled}
              aria-label={t(keyPrefix + '.labelClearFormatting')}
            >
              <FormatClearIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelUndo')}>
          <span>
            <Button
              onClick={() => editor.chain().focus().undo().run()}
              disabled={!editor.can().chain().focus().undo().run() || isDisabled}
              sx={generateSxButton()}
              aria-label={t(keyPrefix + '.labelUndo')}
            >
              <UndoIcon />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={t(keyPrefix + '.labelRedo')}>
          <span>
            <Button
              onClick={() => editor.chain().focus().redo().run()}
              disabled={!editor.can().chain().focus().redo().run() || isDisabled}
              sx={generateSxButton()}
              aria-label={t(keyPrefix + '.labelRedo')}
            >
              <RedoIcon />
            </Button>
          </span>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <Dialog open={isLinkModalOpen} onClose={handleCloseLinkModal} maxWidth="sm" fullWidth>
        <>
          <DialogTitle>{t(keyPrefix + '.addLinkDialogTitle')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="url"
              label={t(keyPrefix + '.addLinkDialogUrlField')}
              type="text"
              fullWidth
              value={urlFieldValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (isUrlFieldInvalid) setIsUrlFieldInvalid(false);
                setUrlFieldValue(event.target.value);
              }}
              error={isUrlFieldInvalid ? true : false}
              helperText={isUrlFieldInvalid ? t(keyPrefix + '.addLinkDialogErrorText') : ''}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  setLink();
                }
              }}
            />
            <DialogActions>
              <Button onClick={handleCloseLinkModal}>{t(keyPrefix + '.addLinkDialogCancel')}</Button>
              <Button onClick={setLink} variant="contained">
                {t(keyPrefix + '.addLinkDialogAdd')}
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>

      <Stack
        direction="row"
        className={cc('tiptap-menu', { focused: isFocused, error: isError })}
        sx={{
          mb: 0.5
        }}
      >
        <Stack direction="row" flexWrap="wrap">
          {renderButtons()}
        </Stack>
      </Stack>
    </>
  );
};
