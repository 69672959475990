import { useState, useCallback, Dispatch, SetStateAction, useMemo, useRef, useEffect } from 'react';
import { Button, Menu, MenuItem, Box, Tooltip } from '@mui/material';
import { Lock, Add as AddIcon, Bolt as BoltIcon } from '@mui/icons-material';
import { IMeeting } from 'types';
import { useLocale, useResourceCreate, useResourceShow } from '@koopajs/react';
import { AllTopicType, smartTopicTypes } from 'utils/topicTypeArrays';
import { uniq } from 'lodash';
import { theme } from '../Core/theme';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface IAddTopicButton {
  meeting: IMeeting;
  workspaceId: string | undefined;
  setTopicIdEditable: Dispatch<SetStateAction<string>> | ((arg0: string) => void);
  excludedTopicTypes?: AllTopicType[];
  sx?: SxProps<Theme>;
}

export const AddTopicButton: React.FC<IAddTopicButton> = (props) => {
  const { meeting, setTopicIdEditable, excludedTopicTypes, sx } = props;
  const { t } = useLocale();

  const [addRegularTopicButtonWidth, setAddRegularTopicButtonWidth] = useState(0);
  const addRegularTopicButtonRef = useRef<HTMLButtonElement>(null);
  const [addRegularTopicMenuAnchorEl, setAddRegularTopicMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isAddRegularTopicMenuOpen = Boolean(addRegularTopicMenuAnchorEl);

  const [addSmartTopicButtonWidth, setAddSmartTopicButtonWidth] = useState(0);
  const addSmartTopicButtonRef = useRef<HTMLButtonElement>(null);
  const [addSmartTopicMenuAnchorEl, setAddSmartTopicMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isAddSmartTopicMenuOpen = Boolean(addSmartTopicMenuAnchorEl);

  const keyPrefix = 'MeetingEdit';

  const checkIsIncludedTopicType = (topicType: AllTopicType) => {
    return !excludedTopicTypes?.includes(topicType);
  };

  const uniqMeetingTopicTypes = uniq(meeting?.topics?.map((topic) => topic.type) || []);
  const missingSmartTopicTypes = smartTopicTypes
    .filter((item) => !uniqMeetingTopicTypes.includes(item))
    .filter(checkIsIncludedTopicType);
  const missingRegularNonGenericTopicTypes = (['miscellaneous'] as AllTopicType[])
    .filter((type) => !uniqMeetingTopicTypes.includes(type))
    .filter(checkIsIncludedTopicType);

  const { createResource } = useResourceCreate({
    path: `/meetings/${meeting?.id}/topics`,
    customReducer: {
      path: { resourceType: '/meetings', resourceId: meeting.id },
      mapping: (oldMeeting: object, newMeeting: object) => {
        const newMeetingObject = newMeeting as IMeeting;
        return {
          ...oldMeeting,
          topics: newMeetingObject.topics
        };
      }
    }
  });

  // workaround for loading state with custom reducer
  const { isProcessing } = useResourceShow({ path: '/meetings', id: meeting.id, useCache: true });

  const handleAddTopic = async (type: string): Promise<void> => {
    await createResource({ type, durationSeconds: 600 }, (id: string, resource: object | undefined) => {
      const newResource = resource as { newTopicId?: string };
      if (newResource.newTopicId) setTopicIdEditable(newResource.newTopicId);
    });
    setAddSmartTopicMenuAnchorEl(null);
    setAddRegularTopicMenuAnchorEl(null);
  };

  // track add smart topic button width
  useEffect(() => {
    const addSmartTopicButton = addSmartTopicButtonRef.current;
    if (addSmartTopicButton) {
      const resizeObserver = new ResizeObserver(() => {
        setAddSmartTopicButtonWidth(addSmartTopicButton.offsetWidth);
      });
      resizeObserver.observe(addSmartTopicButton);
      return () => resizeObserver.disconnect();
    }
  }, []);

  // track add regular topic button width
  useEffect(() => {
    const addRegularTopicButton = addRegularTopicButtonRef.current;
    if (addRegularTopicButton) {
      const resizeObserver = new ResizeObserver(() => {
        setAddRegularTopicButtonWidth(addRegularTopicButton.offsetWidth);
      });
      resizeObserver.observe(addRegularTopicButton);
      return () => resizeObserver.disconnect();
    }
  }, []);

  const onClickAddRegularTopicButton = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (missingRegularNonGenericTopicTypes.length === 0) {
        await handleAddTopic('information');
      } else {
        setAddRegularTopicMenuAnchorEl(event.currentTarget);
      }
    },
    [missingRegularNonGenericTopicTypes]
  );
  const onCloseAddRegularTopicMenu = useCallback(() => {
    setAddRegularTopicMenuAnchorEl(null);
  }, []);

  const openAddSmartTopicMenu = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddSmartTopicMenuAnchorEl(event.currentTarget);
  }, []);
  const onCloseAddSmartTopicMenu = useCallback(() => {
    setAddSmartTopicMenuAnchorEl(null);
  }, []);

  const onClickAddTopicMenuItem = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
    const type = event.currentTarget.id.replace('menuitem-', '');
    await handleAddTopic(type);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        py: { xs: 1.5, md: 3 },
        ...sx
      }}
    >
      <Box sx={{ flex: 1, width: '100%' }}>
        <Button
          id="add-regular-topic-button"
          aria-controls={isAddRegularTopicMenuOpen ? 'add-regular-topic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isAddRegularTopicMenuOpen ? 'true' : undefined}
          onClick={onClickAddRegularTopicButton}
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          ref={addRegularTopicButtonRef}
          disabled={isProcessing}
          data-cy="add-regular-topic-button"
        >
          {t(keyPrefix + '.labelAddTopic')}
        </Button>
        <Menu
          id="add-regular-topic-menu"
          anchorEl={addRegularTopicMenuAnchorEl}
          open={isAddRegularTopicMenuOpen}
          onClose={onCloseAddRegularTopicMenu}
          MenuListProps={{
            'aria-labelledby': 'add-regular-topic-button',
            id: 'add-regular-topic-menu_list' // using for cypress
          }}
          PaperProps={{ style: { width: `${addRegularTopicButtonWidth}px` } }}
        >
          {['information', ...missingRegularNonGenericTopicTypes].map((topic) => {
            return (
              <MenuItem key={topic} id={`menuitem-${topic}`} onClick={onClickAddTopicMenuItem}>
                {topic === 'information'
                  ? t(keyPrefix + '.newTopicMenuItem')
                  : t('common:topicTypes.' + topic)}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>

      <Box
        sx={{ mx: 3, my: { xs: 1.5, md: 0 }, textTransform: 'uppercase', opacity: '60%', fontSize: '12px' }}
      >
        {t('common:or')}
      </Box>

      <Box sx={{ flex: 1, width: '100%' }}>
        <Tooltip title={!missingSmartTopicTypes?.length ? t(keyPrefix + '.tooltipSmartTopicsComplete') : ''}>
          <span>
            <Button
              id="add-smart-topic-button"
              aria-controls={isAddSmartTopicMenuOpen ? 'add-smart-topic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isAddSmartTopicMenuOpen ? 'true' : undefined}
              onClick={openAddSmartTopicMenu}
              variant="contained"
              fullWidth
              startIcon={<BoltIcon />}
              sx={{
                background: `linear-gradient(90deg, ${theme.palette.automation.purple} 0%, ${theme.palette.automation.blue} 100%)`,
                '&.Mui-disabled': {
                  background: theme.palette.customGrey?.dark
                }
              }}
              ref={addSmartTopicButtonRef}
              disabled={isProcessing || !missingSmartTopicTypes?.length}
              data-cy="meeting-prepare_add-smart-topic-button"
            >
              {t(keyPrefix + '.labelAddSmartTopic')}
            </Button>
          </span>
        </Tooltip>
        <Menu
          id="add-smart-topic-menu"
          anchorEl={addSmartTopicMenuAnchorEl}
          open={isAddSmartTopicMenuOpen}
          onClose={onCloseAddSmartTopicMenu}
          MenuListProps={{
            'aria-labelledby': 'add-smart-topic-button',
            id: 'meeting-prepare_add-smart-topic-menu_list' // using for cypress
          }}
          PaperProps={{ style: { width: `${addSmartTopicButtonWidth}px` } }}
        >
          {missingSmartTopicTypes.map((topic) => {
            const isInCamera = topic === 'inCamera';
            return (
              <MenuItem key={topic} id={`menuitem-${topic}`} onClick={onClickAddTopicMenuItem}>
                {isInCamera && <Lock sx={{ opacity: '0.54', mr: '6px' }} />}
                {t('common:topicTypes.' + topic)}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </Box>
  );
};
