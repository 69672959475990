import { IDocument, IDocumentRecord } from 'types';
import { useLocale, useResourceShow, useUserShow } from '@koopajs/react';
import { Typography, Box, Stack, Button, Skeleton } from '@mui/material';
import prettyBytes from 'pretty-bytes';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { DocumentDetailsCard } from 'components/DocumentRecords/DocumentDetailsCard';
import { DocumentHistory } from './DocumentHistory';
import DownloadIcon from '@mui/icons-material/Download';
import { AddDocumentToDocumentRecordButton } from './AddDocumentToDocumentRecordButton';
import { Link as RouterLink } from 'react-router-dom';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { useState, useCallback } from 'react';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DeleteDocumentRecord } from 'components/Modals/DeleteDocumentRecord';
import { SystemStyleObject } from '@mui/system';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { getCurrentUserRoles } from 'utils/getCurrentUserRoles';
import { DocumentAnnotation } from 'components/DocumentAnnotation';
import { theme } from 'components/Core/theme';

interface IDocumentRecordProps {
  documentRecord: IDocumentRecord;
}

export const DocumentRecord: React.FC<IDocumentRecordProps> = (props) => {
  const { documentRecord } = props;

  const { t } = useLocale();
  const keyPrefix = 'DocumentRecord';

  const committeeName = useCommitteeName(documentRecord.committeeId as string);

  const { user: currentUser } = useUserShow();

  const isUserDocumentRecordCreatorRole = getCurrentUserRoles({
    activeCommitteeMembers: documentRecord.activeCommitteeMembers,
    userId: currentUser?.id
  }).includes('createDocumentRecords');

  const [isDeleteDocumentRecordOpen, setIsDeleteDocumentRecordOpen] = useState(false);

  const { resource: document, isProcessing } = useResourceShow<IDocument>({
    path: '/documents',
    id: documentRecord?.lastDocumentId
  });

  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteDocumentRecordOpen(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteDocumentRecordOpen(false);
  }, []);

  const renderLastUpdatedTimestamp = ({ sx }: { sx: SystemStyleObject }): React.ReactNode => {
    return (
      <Typography sx={{ opacity: 0.6, ...sx }}>
        {t(keyPrefix + '.lastUpdate')}
        {document && !isProcessing ? (
          <DateTimeStartEnd
            isoStringDate={document.$createdAt}
            dateFormat="DATE_FULL"
            separator={` ${t('common:at')} `}
            isoStringStart={document.$createdAt}
          />
        ) : (
          <Skeleton variant="rounded" sx={{ display: 'inline-block' }}>
            <DateTimeStartEnd
              isoStringDate={'1970-01-01T00:00:00.000Z'}
              dateFormat="DATE_FULL"
              separator={` ${t('common:at')} `}
              isoStringStart={'1970-01-01T00:00:00.000Z'}
            />
          </Skeleton>
        )}
      </Typography>
    );
  };

  return (
    <>
      <Box sx={{ mx: { lg: 18 }, display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* HEADER */}
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={2}>
          <Stack gap={2} sx={{ minWidth: 0, justifyContent: 'space-between' }}>
            {/* date  mobile */}
            {renderLastUpdatedTimestamp({
              sx: { display: { xs: 'block', sm: 'none' }, alignSelf: 'flex-end' }
            })}

            <Box sx={{ display: { xs: 'flex', sm: 'block' }, gap: 2, flex: 1 }}>
              {/* back arrow button mobile*/}
              <Button
                component={RouterLink}
                to="/document-records"
                variant="outlined"
                sx={{ minWidth: 'auto', px: '5px', display: { sm: 'none' } }}
                aria-label={t('common:labelBack')}
              >
                <ArrowBackIcon sx={{ color: 'primary.main' }} />
              </Button>
              {/* title */}
              <Typography
                variant="h6"
                component="h1"
                sx={{ fontSize: '24px', display: 'flex', alignItems: 'center', gap: 1, minWidth: '0px' }}
                className="rr-mask"
              >
                <Box
                  component="span"
                  sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-word'
                  }}
                >
                  {documentRecord.lastDocumentTitle}
                </Box>
              </Typography>
            </Box>

            {/* filename & size */}
            <Typography
              sx={{
                opacity: 0.6,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              {document && !isProcessing ? (
                <>
                  <RenderDocumentIcon fileType={document?.attachedFile.format} />
                  <Box
                    component="span"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: '0px',
                      whiteSpace: 'nowrap'
                    }}
                    data-cy="document-record-view_file-name"
                    className="rr-mask"
                  >
                    {document.attachedFile.name}
                  </Box>
                </>
              ) : (
                <>
                  <Skeleton
                    variant="circular"
                    sx={{ width: '24px', height: '24px', display: 'inline-block' }}
                  />
                  <Skeleton variant="rounded" sx={{ display: 'inline-block' }}>
                    <span>loading_file.pdf</span>
                  </Skeleton>
                </>
              )}
              <span>&#183;</span>
              {document && !isProcessing ? (
                <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                  {prettyBytes(document.attachedFile.sizeBytes, { maximumFractionDigits: 0 })}
                </Box>
              ) : (
                <Skeleton variant="rounded" sx={{ display: 'inline-block' }}>
                  <span>00 kB</span>
                </Skeleton>
              )}
            </Typography>
          </Stack>
          <Stack alignItems="flex-end" justifyContent="space-between" gap={3}>
            {/* date  desktop */}
            {renderLastUpdatedTimestamp({
              sx: { display: { xs: 'none', sm: 'block' }, whiteSpace: 'nowrap' }
            })}

            <Stack direction={{ sm: 'row' }} gap="14px" sx={{ width: '100%', justifyContent: 'end' }}>
              {document?.attachedFile.format !== 'application/pdf' && !isProcessing && (
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  href={`/documents/${document?.id}`}
                  target="_blank"
                  rel="noopener"
                  data-cy="document-record_download-button"
                  sx={{ minWidth: 'max-content' }}
                >
                  {t(keyPrefix + '.downloadDocumentButtonLabel')}
                </Button>
              )}
              {/* update button */}
              <Box>
                {isUserDocumentRecordCreatorRole && document && (
                  <AddDocumentToDocumentRecordButton
                    documentRecord={documentRecord}
                    sx={{ width: { xs: '100%', sm: 'inherit' } }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </Stack>

        {/* PDF VIEWER */}
        <Box className="rr-block">
          {document?.attachedFile.format === 'application/pdf' && (
            <DocumentAnnotation
              documentId={document.id}
              sx={{
                height: '700px'
              }}
              testId="document-record_file-iframe"
            />
          )}
          {document?.attachedFile.format.startsWith('image') && (
            <iframe
              style={{
                border: `1px solid ${theme.palette.customGrey?.light}`,
                backgroundColor: 'rgba(0, 0, 0, 0.11)',
                width: '100%',
                height: '700px'
              }}
              src={document.attachedFileUri}
              data-cy="document-record_file-iframe"
            />
          )}
        </Box>

        {/* DOCUMENT EDIT CARD */}
        <DocumentDetailsCard
          documentRecord={documentRecord}
          userHasEditAccess={isUserDocumentRecordCreatorRole}
          committeeName={committeeName}
        />

        {/* DOCUMENT HISTORY */}
        <DocumentHistory
          documentRecord={documentRecord}
          canUserUploadDocuments={isUserDocumentRecordCreatorRole}
        />

        <>
          <DeleteDocumentRecord
            documentRecord={documentRecord}
            isOpen={isDeleteDocumentRecordOpen}
            onClose={handleCloseDeleteModal}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: isUserDocumentRecordCreatorRole ? 'space-between' : 'flex-end',
              flexDirection: { xs: 'row-reverse', sm: 'row' }
            }}
          >
            {isUserDocumentRecordCreatorRole && (
              <Button onClick={handleOpenDeleteModal} color="error" data-cy="document-record_delete-button">
                {t(keyPrefix + '.labelDeleteDocumentRecord')}
              </Button>
            )}

            {/* desktop 'back' button */}
            <Button
              component={RouterLink}
              to="/document-records"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              {t('common:labelBack')}
            </Button>
            {/* mobile back arrow button */}
            <Button
              component={RouterLink}
              to="/document-records"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
          </Box>
        </>
      </Box>
    </>
  );
};
