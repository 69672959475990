import { useLocale, useUserShow } from '@koopajs/react';
import { Box, Typography, Chip } from '@mui/material';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { IMeeting } from '../../../types';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { formatDate } from 'utils/DateTime/formatDate';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';
import { ParticipantsWithTitlesAccordionList } from 'components/BoardMemberView/BoardMemberViewBody/TopicCard/QuorumCard/ParticipantsWithTitlesAccordionList';
import { MeetingLocationInformation } from '../../MeetingLocationInformation';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';

interface IMeetingViewHeaderCardProps {
  meeting: IMeeting;
}

export const MeetingViewHeaderCard: React.FC<IMeetingViewHeaderCardProps> = (props) => {
  const { meeting } = props;
  const keyPrefix = 'MeetingView.MeetingViewHeaderCard';

  const { user } = useUserShow();

  const { isUserMeetingCreatorRole } = useMeetingStatus({
    meeting,
    user
  });

  const { t, locale } = useLocale();
  const meetingType = useCommitteeName(meeting?.committeeId as string);

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(meeting?.activeCommitteeMembers);

  return (
    <Box sx={{ display: { sm: 'flex' } }}>
      <Box
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3
          }}
        >
          <Typography variant="overline">{t(keyPrefix + '.noticeLabel')}</Typography>
          {!meeting.minutesStartedAt && isUserMeetingCreatorRole && (
            <Chip
              label={
                meeting.invitationSentAt
                  ? t(keyPrefix + '.chipNoticeSent')
                  : t(keyPrefix + '.chipNoticeInPreparation')
              }
              sx={{ backgroundColor: meeting.invitationSentAt ? 'greenTwo.60' : 'customGrey.main' }}
              data-cy="meeting-view_notice_state-chip"
            />
          )}
        </Box>

        {/* DATE & COMMITTEE */}
        <Typography
          sx={{
            display: 'inline',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '0px'
          }}
          component="span"
          variant="h6"
          color="text.primary"
        >
          {meeting.startDateTime &&
            formatDate({
              isoString: meeting.startDateTime,
              locale,
              format: 'DATE_HUGE'
            }) + ' | '}
          {meetingType}
        </Typography>

        {/* TITLE & TIME */}
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', flexDirection: { xs: 'column', md: 'row' } }}>
          {meeting.title && (
            <Typography
              variant="body2"
              data-cy="meeting-view_header-card_title"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
              }}
            >
              {meeting.title}
            </Typography>
          )}
          {meeting.startDateTime && (
            <Typography variant="body2" sx={{ display: 'flex', gap: 1 }}>
              <Box component="span" sx={{ display: { xs: 'none', md: 'inline' } }}>
                &#183;
              </Box>
              <Box component="span" color="text.secondary">
                <DateTimeStartEnd isoStringStart={meeting.startDateTime} isoStringEnd={meeting.endDateTime} />
              </Box>
            </Typography>
          )}
        </Box>

        {/* LOCATION */}
        <MeetingLocationInformation meeting={meeting} />

        {/* GUESTS */}
        {meeting?.participants && meeting.participants.length > 0 && (
          <Box sx={{ mt: '10px' }}>
            <ParticipantsWithTitlesAccordionList
              userIds={meeting.participants.map((p) => p.userId)}
              committeeMembersTitles={committeeMembersTitlesObj}
              label={`${t('common:guests')} (${meeting?.participants?.length})`}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
