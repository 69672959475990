import { Box } from '@mui/material';
import { ITopic } from 'types';
import { BoardMemberViewTopicCardAccordion } from '../Components/Accordion';
import { BoardMemberViewTopicCardAccordionSummaryChildren } from '../Components/AccordionSummaryChildren';
import { PastMinutesItem } from './PastMinutesItem';
import { useParamsKey } from '@koopajs/react';
import { ViewPastMeeting } from 'components/Dialogs/ViewPastMeeting';
import { useBoardMemberViewContext } from 'components/BoardMemberView/BoardMemberViewProvider';
import { useState } from 'react';
import { useDocumentCount } from 'components/hooks/BoardMemberView/useDocumentCount';
import { DocumentList } from '../Components/DocumentList';
import { TopicDescription } from '../Components/TopicDescription';

interface IBoardMemberViewPastMinutesApprovalCard {
  topic: ITopic;
  index: number;
}

export const BoardMemberViewPastMinutesApprovalCard: React.FC<IBoardMemberViewPastMinutesApprovalCard> = (
  props
) => {
  const { topic, index } = props;

  const boardMemberViewContext = useBoardMemberViewContext();
  const { meeting } = boardMemberViewContext;

  const hasPastMinutesToApprove = Boolean(topic.pastMeetingsToApprove?.length);
  const pastMeetingId = useParamsKey('pastMeetingId');

  const [currentVisibleMinuteIndex, setCurrentVisibleMinuteIndex] = useState(0);

  const {
    linkDocumentsCount,
    setLinkDocumentsCount,
    fileDocumentsCount,
    setFileDocumentsCount,
    hasDocuments
  } = useDocumentCount({ topic });

  const hasContent = Boolean(topic.description || hasPastMinutesToApprove || hasDocuments);

  return (
    <>
      <ViewPastMeeting pastMeetingId={pastMeetingId} pathOnClose={`/meeting-annotate/${meeting?.id}`} />

      <BoardMemberViewTopicCardAccordion
        topic={topic}
        index={index}
        isDisabled={!hasContent}
        accordionSummary={
          <BoardMemberViewTopicCardAccordionSummaryChildren
            topic={topic}
            index={index}
            pastMinutesToApproveItemIndex={currentVisibleMinuteIndex}
            linkDocumentsCount={linkDocumentsCount}
            fileDocumentsCount={fileDocumentsCount}
          />
        }
        accordionDetails={
          <>
            {topic.description && <TopicDescription description={topic.description} />}

            {hasDocuments && (
              <Box sx={{ mb: '24px' }}>
                <DocumentList
                  path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
                  setLinkDocumentsCount={setLinkDocumentsCount}
                  setFileDocumentsCount={setFileDocumentsCount}
                />
              </Box>
            )}

            {hasPastMinutesToApprove &&
              topic.pastMeetingsToApprove?.map((pastMinutes, i) => {
                const { meetingId } = pastMinutes;
                return (
                  <Box
                    key={meetingId}
                    sx={{
                      p: { xs: '12px 8px', lg: '24px 64px' },
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <PastMinutesItem
                      minutesId={meetingId}
                      setCurrentVisibleMinuteIndex={setCurrentVisibleMinuteIndex}
                      index={i}
                    />
                  </Box>
                );
              })}
          </>
        }
      />
    </>
  );
};
