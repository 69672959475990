import { Typography, Box, Stack, Link as MuiLink } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { ITopic, IActiveCommitteeMembers } from 'types';
import { IUser } from '@koopajs/app';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { UsersCheckboxesRows } from 'components/UsersCheckboxesRows';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';

interface IInCameraFieldProps {
  userHasEditAccess?: boolean;
  isEditable: boolean;
  isReview?: boolean;
  handleSetIsEditable?: () => void;
  topic: ITopic;
  users: IUser[];
  activeCommitteeMembers?: IActiveCommitteeMembers[];
}

export const InCameraField: React.FC<IInCameraFieldProps> = (props) => {
  const {
    isEditable,
    userHasEditAccess,
    handleSetIsEditable,
    isReview,
    topic,
    users,
    activeCommitteeMembers
  } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.TopicCard.InCameraField';

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(activeCommitteeMembers);

  return (
    <>
      {/* PERMISSIONS */}

      {userHasEditAccess && !isEditable && !isReview && (
        <Box>
          {topic.visibleBy && topic.visibleBy.length > 0 ? (
            <Typography>{t(keyPrefix + '.permissionsSet', { count: topic.visibleBy.length })}</Typography>
          ) : (
            <MuiLink
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'link.main',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: '400',
                textDecoration: 'none'
              }}
              onClick={handleSetIsEditable}
            >
              <LockOutlinedIcon sx={{ mr: 1 }} />
              {t(keyPrefix + '.selectPermissions')}
            </MuiLink>
          )}
        </Box>
      )}

      {isEditable && (
        <>
          <Stack direction="row" alignItems="center" spacing={2}>
            <PeopleAltIcon color="action" />
            <Typography variant="body1">{t(keyPrefix + '.permissions')}</Typography>
          </Stack>
          <Typography variant="caption">
            <Trans i18nKey={keyPrefix + '.selectPeople-html'} t={t} />
          </Typography>

          {/* PERMISSIONS */}
          <UsersCheckboxesRows
            usersIds={users.map((u) => u.id)}
            defaultValues={topic.visibleBy}
            formName="visibleBy"
            isCheckboxLabelAvatar
            committeeMembersTitles={committeeMembersTitlesObj}
            sxProps={{ mt: 0 }}
          />
        </>
      )}
    </>
  );
};
