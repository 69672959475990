import { useLocale } from '@koopajs/react';
import { Stack, Typography, Box } from '@mui/material';
import { NominationsField } from './NominationsField';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { IMeeting, IMinute, ITopic } from 'types';
import { formatTime } from 'utils/DateTime/formatTime';
import { ApprovedMinutesList } from 'components/Minutes/ApprovedMinutesList';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { checkIsDecisionTopicType } from 'utils/topicTypeArrays';
import { theme } from 'components/Core/theme';

interface ITopicCardMinutesPrintProps {
  topic: ITopic;
  order: number;
  meeting: IMeeting | IMinute;
}
export const TopicCardMinutesPrint: React.FC<ITopicCardMinutesPrintProps> = (props) => {
  const { topic, order, meeting } = props;

  const { t, locale } = useLocale();

  const { membersAgainst, membersAbstained, membersFor } = topic?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  let adjournmentAt = meeting?.minutesEndedAt;
  if (topic.type === 'adjournment' && topic.version === 1) {
    if (topic.resolutionOutcome?.recordedAt && topic.resolutionOutcome?.outcome === 'approved') {
      adjournmentAt = topic.resolutionOutcome?.recordedAt;
    } else {
      adjournmentAt = '';
    }
  }

  const isDecisionTopic = checkIsDecisionTopicType(topic.type);

  return (
    <Stack direction="column" spacing={1}>
      {isDecisionTopic &&
        topic?.resolutionOutcome?.outcome &&
        topic?.isResolution &&
        topic.resolutionNumber && (
          <Typography
            sx={{
              fontSize: '12px',
              '@media print': {
                breakInside: 'avoid',
                wordBreak: 'break-word',
                breakAfter: 'avoid', // Prevent the reso number from being the last item
                breakBefore: 'auto'
              }
            }}
            variant="overline"
          >
            {t('common:resolution_one')}
            {` #${topic.resolutionNumber}`}
          </Typography>
        )}
      {/* ORDER & TITLE */}
      <Typography
        sx={{
          width: '100%',
          fontWeight: 'bold',
          '@media print': {
            breakInside: 'avoid',
            wordBreak: 'break-word',
            breakAfter: 'avoid',
            breakBefore: 'auto'
          }
        }}
        variant="body2"
      >
        {order}. {topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}
      </Typography>
      {/* QUORUM  */}
      {topic.type === 'quorum' && (
        <Box>
          {meeting?.minutesStartedAt && (
            <Typography variant="body2">
              {t('MeetingStateReview.meetingStarted', {
                time: formatTime({ isoString: meeting.minutesStartedAt, locale })
              })}
            </Typography>
          )}
          {topic.quorumReachedAt && (
            <Typography variant="body2">
              {t('MeetingStateReview.quorumReached', {
                time: formatTime({ isoString: topic.quorumReachedAt, locale })
              })}
            </Typography>
          )}
        </Box>
      )}
      {/* NOMINATIONS */} {topic.type === 'nominations' && <NominationsField topic={topic} />}
      {/* BODY FIELD */}
      {[
        'quorum',
        'nominations',
        'agendaApproval',
        'pastMinutesApproval',
        'ceoReport',
        'information',
        'discussion',
        'resolution',
        'miscellaneous',
        'adjournment'
      ].includes(topic.type) && (
        <>
          {topic.notes && (
            <RichTextReadOnly
              value={topic.notes}
              sxEditorContainer={{
                ...theme.typography.body2
              }}
            />
          )}
        </>
      )}
      {/* PAST MINUTES THAT HAVE BEEN APPROVED */}
      {topic.type === 'pastMinutesApproval' && (
        <ApprovedMinutesList approvedMinutes={topic.pastMeetingsToApprove} />
      )}
      {/* ADJOURNMENT */}
      {topic.type === 'adjournment' && adjournmentAt ? (
        <Box>
          <Typography variant="body2">
            {t('MeetingStateReview.adjournmentAt', {
              time: formatTime({ isoString: adjournmentAt, locale })
            })}
          </Typography>
        </Box>
      ) : null}
      {/* RESOLUTION OUTCOME */}
      {isDecisionTopic && topic?.resolutionOutcome?.outcome && (
        <Stack
          spacing={1}
          sx={{
            '@media print': {
              breakInside: 'avoid'
            }
          }}
        >
          <Box>
            <ResolutionOutcomeText topic={topic} />
          </Box>
          {showResolutionOutcomeVotes && (
            <Typography variant="body2">
              <ResolutionOutcomeVotes topic={topic} hideIcon />
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};
