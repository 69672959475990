import { Box, Button, IconButton, Stack, Paper } from '@mui/material';
import { FormContext, useLocale, useResourceList } from '@koopajs/react';
import { ICommittee, IMeeting, ITopic } from 'types';
import { useContext, useState } from 'react';
import { ExpandMore as ExpandMoreIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { NewCheckbox } from 'components/temp/NewCheckbox';
import { formatDate } from 'utils/DateTime/formatDate';

interface IPastMinutesApprovalFieldProps {
  isProcessing: boolean;
  meetings: IMeeting[];
  topic: ITopic;
}

export const PastMinutesApprovalField: React.FC<IPastMinutesApprovalFieldProps> = (props) => {
  const { isProcessing, meetings, topic } = props;

  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);

  const toggleAccordion = () => {
    setAccordionExpanded((prev) => !prev);
  };

  const { t, locale } = useLocale();
  const keyPrefix = 'Components.TopicCard.PastMinutesApprovalField';

  const context = useContext(FormContext);

  const formValues = context.form?.watch() as { pastMeetingsToApprove: { [k: string]: boolean } } | undefined;

  const isFormProcessing = context.isProcessing;

  const areMinutesSelected =
    formValues &&
    formValues.pastMeetingsToApprove &&
    Object.values(formValues.pastMeetingsToApprove).some(Boolean);

  // only show meetings that aren't already added to topic
  const pastMeetingsInTopicIds = topic.pastMeetingsToApprove?.map((pm) => pm.meetingId) || [];
  const filteredMeetings = meetings.filter((m) => !pastMeetingsInTopicIds.includes(m.id));

  const isThereMeetingsToAdd = Boolean(filteredMeetings.length);

  const { resources: committees } = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  const getCommitteeName = (committeeId: string): string | undefined => {
    const committee = committees.find((c) => c.id === committeeId);
    return committee?.name;
  };

  if (!filteredMeetings.length) return null;

  return (
    <Paper variant="outlined" sx={{ mt: 1, overflow: 'hidden' }}>
      {/* TITLE */}
      <Stack justifyContent="end" sx={{ backgroundColor: 'customGrey.lighter', px: 1 }}>
        <Stack direction="row" alignItems="center">
          <AccessTimeIcon color="action" sx={{ mr: '6px' }} aria-hidden />
          <Box
            sx={{ mr: 'auto' }}
            id="past-minutes-approval-field_title"
            data-cy="past-minutes-approval-field_title"
          >
            {t(keyPrefix + '.title')}
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={isProcessing || isFormProcessing}
            sx={{
              visibility: areMinutesSelected && accordionExpanded ? 'visible' : 'hidden',
              display: { xs: 'none', sm: 'block' },
              ml: 1
            }}
            data-cy="meeting-prepare_past-minutes-approval-field_add-button"
            size="small"
          >
            {t('common:add')}
          </Button>
          <IconButton
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => toggleAccordion()}
            aria-expanded={accordionExpanded}
            aria-controls="past-minutes-approval-field_body"
            aria-label={t('common:expandAccordion')}
            sx={{
              transform: accordionExpanded ? 'rotate(180deg)' : undefined,
              transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              visibility: isThereMeetingsToAdd ? 'visible' : 'hidden'
            }}
          >
            <ExpandMoreIcon />{' '}
          </IconButton>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          disabled={isProcessing || isFormProcessing}
          sx={{
            display: areMinutesSelected && accordionExpanded ? { xs: 'block', sm: 'none' } : 'none',
            mt: 1
          }}
          data-cy="meeting-prepare_past-minutes-approval-field_add-button"
          size="small"
        >
          {t('common:add')}
        </Button>
      </Stack>
      {/* BODY */}
      <Box
        aria-labelledby="past-minutes-approval-field_title"
        id="past-minutes-approval-field_body"
        data-cy="past-minutes-approval-field_body"
        role="region"
        sx={accordionExpanded ? {} : { display: 'none' }}
      >
        {filteredMeetings.map((minutes) => {
          const committeeName = getCommitteeName(minutes.committeeId);
          const date = minutes.startDateTime
            ? formatDate({
                isoString: minutes.startDateTime,
                locale,
                format: 'DATE_FULL'
              })
            : '';

          return (
            <Box key={minutes.id} data-cy="meeting-prepare_past-minutes-approval-field_row">
              <NewCheckbox
                name={`pastMeetingsToApprove[${minutes.id}]`}
                i18n={{
                  keyPrefix: 'common:CheckboxLabelInterpolation',
                  options: {
                    label: `${date} | ${committeeName} | ${minutes?.title}`
                  }
                }}
                isOptional={true}
                sx={{
                  px: '10px',
                  my: 0,
                  whiteSpace: 'pre-wrap',
                  '& .MuiFormControlLabel-label': { py: 1 },
                  '& .MuiFormControlLabel-root': { alignItems: 'flex-start' }
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};
