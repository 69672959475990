import { useResourceList } from '@koopajs/react';
import { createContext, ReactNode, useContext, useState } from 'react';
import { IDocument, IMeeting } from 'types';

// eslint-disable-next-line @rushstack/typedef-var
const BoardMemberView = createContext<
  | {
      currentTopicId: string | undefined;
      isDrawerOpen: boolean;
      setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
      setCurrentTopicId: React.Dispatch<React.SetStateAction<string | undefined>>;
      meeting: IMeeting;
      pdfDocuments: IDocument[];
    }
  | undefined
>(undefined);

interface IBoardMemberViewProviderProps {
  meeting: IMeeting;
}

export const BoardMemberViewProvider: React.FC<
  IBoardMemberViewProviderProps & { children: React.ReactNode }
> = ({ meeting, children }) => {
  const [currentTopicId, setCurrentTopicId] = useState<string | undefined>(
    meeting?.topics?.[0]?.id || undefined
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { resources: documents } = useResourceList<IDocument>({
    path: `/meetings/${meeting.id}/documents`
  });

  const pdfDocuments = documents.filter((doc) => doc.attachedFile.format === 'application/pdf');

  return (
    <BoardMemberView.Provider
      value={{
        currentTopicId,
        setCurrentTopicId,
        meeting,
        isDrawerOpen,
        setIsDrawerOpen,
        pdfDocuments
      }}
    >
      {children}
    </BoardMemberView.Provider>
  );
};

export const useBoardMemberViewContext = () => {
  const context = useContext(BoardMemberView);

  if (!context) {
    throw new Error('useBoardMemberViewContext must be used within an BoardMemberViewProvider');
  }
  return context;
};
