import { IApprovedTopic } from 'types';
import { Stack, Typography, Paper, Box } from '@mui/material';
import { useLocale } from '@koopajs/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DateTimeStartEnd } from '../DateTimeStartEnd';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface IInCameraMeetingHeaderCard {
  approvedTopic?: IApprovedTopic;
  isPrintVersion?: boolean;
}

// Component used for InCamera book

export const InCameraMeetingHeaderCard: React.FC<IInCameraMeetingHeaderCard> = (props) => {
  const { approvedTopic, isPrintVersion } = props;

  const minutesType = useCommitteeName(approvedTopic?.meetingCommitteeId || '');

  const { t } = useLocale();

  return (
    <>
      <Paper
        sx={{ p: isPrintVersion ? 0 : 3, my: 2, border: isPrintVersion ? 0 : undefined }}
        variant="outlined"
      >
        <Stack sx={{ flexGrow: 1 }}>
          {/* desktop - title */}
          <Typography sx={{ display: { xs: 'none', md: 'flex' }, marginBottom: '14px' }} variant="h6">
            <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
              {t('InCamera.viewInCameraDialogTitle')}
            </Box>{' '}
            {minutesType && (
              <>
                <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
                  &nbsp; | &nbsp;
                </Box>
                <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
                  {minutesType}
                </Box>
              </>
            )}
          </Typography>
          {/* mobile - title: 2 lines */}
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              marginBottom: '14px',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h6"> {t('InCamera.viewInCameraDialogTitle')}</Typography>
            <Typography variant="h6">{minutesType}</Typography>
          </Box>

          <Typography
            sx={{ marginBottom: '14px', fontSize: '18px' }}
            variant="body1"
            data-cy="approved-topic-header-card_meeting-title"
          >
            {approvedTopic?.meetingTitle}
          </Typography>
          {/* DATE */}
          <Stack key="date" sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {!isPrintVersion && (
              <CalendarTodayIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />
            )}
            <Typography variant="body2" sx={{ display: 'inline' }}>
              <DateTimeStartEnd
                isoStringDate={approvedTopic?.meetingStartDateTime}
                dateFormat="DATE_HUGE"
                separator={` ${t('common:labelFrom2')} `}
                isoStringStart={approvedTopic?.meetingStartDateTime}
                isoStringEnd={approvedTopic?.meetingEndDateTime}
              />
            </Typography>
            {approvedTopic?.meetingLocation && approvedTopic?.meetingLocation?.type === 'remote' && (
              <>
                <Box sx={{ mx: 4 }}>&#183;</Box>
                <Typography key="locationType" variant="body2">
                  {t(`common:locationType.${approvedTopic.meetingLocation?.type}`)}
                </Typography>
              </>
            )}
          </Stack>
          {/* ADDRESS */}
          <Stack
            sx={{
              flexDirection: { xs: 'colum', sm: 'row' },
              alignItems: { xs: 'left', sm: 'center' },
              mt: { xs: 1, md: 0 }
            }}
          >
            {approvedTopic?.meetingLocation?.address && (
              <>
                <Box sx={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'center' }}>
                  {!isPrintVersion && (
                    <LocationOnIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />
                  )}
                  <Typography variant="body2">{approvedTopic?.meetingLocation?.address}</Typography>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' }, mx: 4, my: 1 }}>&#183;</Box>
              </>
            )}

            {approvedTopic?.meetingLocation && approvedTopic?.meetingLocation?.type !== 'remote' && (
              <Typography key="locationType" variant="body2" sx={{ ml: { xs: 3.5, sm: 0 } }}>
                {t(`common:locationType.${approvedTopic.meetingLocation?.type}`)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};
