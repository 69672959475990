import { useRef, useCallback } from 'react';
import { IResolution } from 'types';
import { Box, Typography, Paper, Divider } from '@mui/material';
import { Dialog, Loading } from '@koopajs/mui';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { DocumentList } from '../DocumentList';
import { RenderPrintButton } from '../RenderPrintButton';
import { ViewResolutionPrintVersion } from './ViewResolutionPrintVersion';

import { SignatureList } from 'components/Signature/SignatureList';
import { useHistory } from 'react-router-dom';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { useLocale } from '@koopajs/react';
import { isEmpty } from 'lodash';
import { TopicMinutesDetailsCard } from 'components/TopicMinutesDetailsCard';
import { WrittenResolutionDialogDetailsCard } from 'components/Resolutions/WrittenResolutionDialogDetailsCard';
import { generateResolutionTimeline } from 'utils/generateResolutionTimeline';
import { EventsTimeline } from 'components/EventsTimeline';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';

interface IViewResolutionProps {
  resolution?: IResolution;
  isResolutionProcessing?: boolean;
}

export const ViewResolution: React.FC<IViewResolutionProps> = (props) => {
  const { resolution, isResolutionProcessing } = props;
  const keyPrefix = 'Resolutions.DialogView';
  const history = useHistory();
  const printComponentRef = useRef(null);
  const { t } = useLocale();

  const isAttachedToMeetingTopic = Boolean(resolution?.attachedToMeetingTopic);
  const isAttachedToEmail = Boolean(resolution?.attachedToEmail);

  const { membersAgainst, membersAbstained, membersFor } = resolution?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  const handleOnClose = useCallback(() => {
    history.push({ pathname: `/resolutions`, search: history.location?.search });
  }, []);

  const timelineEvents = generateResolutionTimeline(resolution);

  const signaturesWithTitles = getSignaturesWithTitles(resolution);

  if (!resolution) return null;

  return (
    <Dialog.View
      isOpen={Boolean(resolution)}
      onClose={handleOnClose}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix }}
      footerActions={
        <RenderPrintButton keyPrefix={keyPrefix} componentRef={printComponentRef} size="small" />
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'view-resolution-dialog' } }}
    >
      <Box sx={{ display: 'none' }}>
        <ViewResolutionPrintVersion resolution={resolution} ref={printComponentRef} />
      </Box>

      {isResolutionProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', my: 3 }}>
          <Paper
            elevation={6}
            sx={{
              mb: 5,
              p: { xs: '18px', lg: '60px' },
              maxWidth: '912px',
              display: 'flex',
              flexDirection: 'column',
              gap: '14px',
              minWidth: 0,
              width: '100%'
            }}
          >
            <>
              {isAttachedToMeetingTopic && resolution.attachedToMeetingTopic?.minuteId && (
                <TopicMinutesDetailsCard
                  minuteId={resolution.attachedToMeetingTopic.minuteId}
                  title={t('Resolutions.resolutionExtract')}
                  resolutionNumber={resolution.resolutionNumber}
                />
              )}

              {resolution.attachedToEmail && <WrittenResolutionDialogDetailsCard resolution={resolution} />}

              <Divider />

              <Typography
                variant="body1"
                data-cy="view-resolution-dialog_resolution-title"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0px' }}
              >
                {resolution.title
                  ? resolution.title
                  : resolution.attachedToMeetingTopic?.type
                  ? t('common:topicTypes.' + resolution.attachedToMeetingTopic.type)
                  : null}
              </Typography>

              {resolution?.notes && (
                <>
                  <Typography variant="body2" component="div">
                    <RichTextReadOnly value={resolution.notes} />
                  </Typography>
                </>
              )}

              {isAttachedToMeetingTopic &&
                resolution?.resolutionOutcome &&
                !isEmpty(resolution.resolutionOutcome) && (
                  <>
                    <Typography variant="body1">
                      <ResolutionOutcomeText topic={resolution} />
                    </Typography>

                    {showResolutionOutcomeVotes && (
                      <Box>
                        <ResolutionOutcomeVotes topic={resolution} hideIcon />
                      </Box>
                    )}
                  </>
                )}
              {resolution?.documentsIds && resolution?.documentsIds?.length > 0 && (
                <>
                  <DocumentList
                    path={`/resolutions/${resolution.id}/documents`}
                    isAccordion={true}
                    accordionOptions={{
                      defaultExpanded: true,
                      documentsVisible: 0
                    }}
                  />
                </>
              )}
              {isAttachedToEmail && resolution.resolutionOutcome?.outcome === 'declined' && (
                <Typography data-cy="view-resolution-dialog_written-resolution-declined">
                  {t(keyPrefix + '.writtenResolutionSignatureDescriptionDeclined')}
                </Typography>
              )}

              {signaturesWithTitles && (
                <>
                  <Divider />
                  <SignatureList
                    signatures={signaturesWithTitles}
                    description={
                      isAttachedToEmail &&
                      resolution.resolutionOutcome?.outcome === 'approved' && (
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                          {t(keyPrefix + '.writtenResolutionSignatureDescriptionApproved')}
                        </Box>
                      )
                    }
                  />
                </>
              )}
            </>
          </Paper>
        </Box>
      )}
      <Box sx={{ mt: 3 }}>
        <EventsTimeline timelineEvents={timelineEvents} />
      </Box>
    </Dialog.View>
  );
};
