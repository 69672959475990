/* eslint-disable react/jsx-no-bind */
import { IMeeting, ICommittee } from 'types';
import { IUserPublicProfile } from '@koopajs/app';
import { useCallback, useState, useRef } from 'react';
import {
  useUserShow,
  useLocale,
  useParamsKey,
  useResourceShow,
  useWorkspaceShow,
  useResourceList
} from '@koopajs/react';
import {
  Button,
  Box,
  Stack,
  Typography,
  Paper,
  Divider,
  ClickAwayListener,
  Tooltip,
  List,
  ListItem
} from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { ViewMinutesPrintVersion } from 'components/Dialogs/ViewMinutesPrintVersion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { PageContainer } from 'components/temp/PageContainer';
import { ViewPastMeeting } from 'components/Dialogs/ViewPastMeeting';
import { ValidateMinutesButton } from 'components/Meetings/Review/ValidateMinutesButton';
import { EventsTimeline } from 'components/EventsTimeline';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { Loading } from '@koopajs/mui';
import { Trans } from 'react-i18next';
import { theme } from '../components/Core/theme';
import { LightBulbIconButton } from 'components/LightBulbIconButton';
import { TopicCardMinutesMoveable } from 'components/TopicCard/TopicCardMinutesMovable';
import { checkIsEndTopic, checkIsUnmovableTopicType } from 'utils/topicTypeArrays';
import { AddTopicButton } from 'components/Meetings/AddTopicButton';
import { alphabeticalUserSortFunction } from 'utils/alphabeticalUserSortFunction';
import { useFeatureGate } from '@statsig/react-bindings';

export const i18nTransComponents: { [k: string]: React.ReactElement } = {
  span: <span style={{ color: 'grey' }} />
};

export const MeetingReview: React.FC = () => {
  const meetingId = useParamsKey('meetingId');
  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId
  });

  const { resources: users } = useResourceList<IUserPublicProfile>({
    path: '/users',
    searchParams: { size: 50 }
  });

  const { resource: committee } = useResourceShow<ICommittee>({
    path: '/committees',
    id: meeting?.committeeId
  });

  const { user: currentUser } = useUserShow();

  const { hasMeetingEnded } = useMeetingStatus({ meeting, user: currentUser });
  const { value: isFullTopicEditEnabled } = useFeatureGate('minutes_review_topics');

  const history = useHistory();

  const workspace = useWorkspaceShow();

  const [isGuideTooltipOpen, setIsGuideTooltipOpen] = useState(false);
  const handleGuideTooltipClose = () => {
    setIsGuideTooltipOpen(false);
  };
  const handleGuideTooltipOpen = () => {
    setIsGuideTooltipOpen(true);
  };

  const doesUserHaveAccessToPage = hasMeetingEnded;
  if (meeting && !doesUserHaveAccessToPage) {
    history.push(`/meetings/${meetingId}`);
  }

  const topics = meeting?.topics;

  const filteredTopicsStart = topics?.filter((topic) => !checkIsEndTopic(topic.type));
  const filteredTopicsEnd = topics?.filter((topic) => checkIsEndTopic(topic.type));

  const notEndTopicCount =
    meeting?.topics?.reduce((acc, topic) => {
      return !checkIsEndTopic(topic.type) ? acc + 1 : acc;
    }, 0) || 0;

  const urlKey = useParamsKey('key');
  const isAgendaVisible = urlKey === 'agenda';

  const componentRef = useRef(null);

  const { t } = useLocale();

  const keyPrefix = 'MeetingStateReview';

  const [topicEditableObject, setTopicEditableObject] = useState<{ topicId: string; isBrandNew?: boolean }>({
    topicId: ''
  });

  const handleSetTopicEditable = useCallback((topicId: string) => {
    return () => setTopicEditableObject({ topicId });
  }, []);

  const handleUnsetTopicEditable = useCallback(() => {
    setTopicEditableObject({ topicId: '' });
  }, []);

  const isUserReviewer =
    currentUser?.id && meeting?.reviewers?.some((reviewer) => reviewer.userId === currentUser.id);

  const hasUserAlreadyReviewed =
    currentUser && meeting?.reviews?.map((signature) => signature.userId).includes(currentUser?.id);

  const userHasEditAccess = !meeting?.boardApprovedAt && isUserReviewer ? true : false;

  const uniqueUserReviewsCount = meeting?.reviewers?.filter((reviewers) => reviewers.latestReview).length;

  const timelineEvents = generateMeetingTimeline(meeting);

  const committeeType = committee?.name;

  const isUserParticipantToMeeting = (u: IUserPublicProfile): boolean => {
    return Boolean(meeting?.participants?.find((p) => p.userId === u.id));
  };
  const participants = users.filter(isUserParticipantToMeeting).sort(alphabeticalUserSortFunction);

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  } else if (!doesUserHaveAccessToPage) {
    // loading component while we wait for the redirect to the view page
    return (
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
      </Stack>
    );
  }
  return (
    <PageContainer
      sxChildren={{ padding: { xs: 3, md: '24px 56px' } }}
      className="rr-block"
      testId="meeting-review-page"
    >
      <Box sx={{ display: 'none' }}>
        <ViewMinutesPrintVersion minutes={meeting} topics={topics} ref={componentRef} />
      </Box>

      <Breadcrumbs
        hierarchyArray={[{ path: '/pending-review', text: t('common:navigation.dashboard') }]}
        pageTitle={userHasEditAccess ? t(keyPrefix + '.titleReview') : t(keyPrefix + '.titleView')}
      />

      <Box>
        <Box sx={{ mt: 4, mb: 2 }}>
          <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            justifyContent={{ sm: 'space-between' }}
            alignItems={{ sm: 'center' }}
          >
            <Stack direction="row" alignItems="center">
              <Button
                component={RouterLink}
                to="/"
                variant="outlined"
                sx={{ minWidth: 0, px: '5px', display: { sm: 'none' }, mr: '14px' }}
                aria-label={t('common:labelBack')}
              >
                <ArrowBackIcon sx={{ color: 'primary.main' }} />
              </Button>

              <Stack flexDirection="row" alignItems="center">
                <Typography variant="h1" sx={{ display: 'inline-block', fontWeight: 500, fontSize: '20px' }}>
                  {userHasEditAccess ? t(keyPrefix + '.titleReview') : t(keyPrefix + '.titleView')}
                </Typography>
                <ClickAwayListener onClickAway={handleGuideTooltipClose}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            p: 0,
                            backgroundColor: 'transparent',
                            maxWidth: '600px',
                            fontWeight: 400
                          }
                        }
                      }}
                      onClose={handleGuideTooltipClose}
                      open={isGuideTooltipOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <Paper
                          sx={{
                            border: `1px solid ${theme.palette.customGrey.light}`,
                            p: 3
                          }}
                        >
                          <Typography variant="h6">{t(keyPrefix + '.guideTooltip.title')}</Typography>
                          <List
                            sx={{
                              listStyle: 'decimal',
                              listStylePosition: 'inside',
                              fontSize: 'initial',
                              '& > li::marker': { fontWeight: 'bold' }
                            }}
                          >
                            {['bullet1', 'bullet2', 'bullet3', 'bullet4', 'bullet5'].map((bullet) => (
                              <ListItem key={bullet} disablePadding sx={{ display: 'list-item' }}>
                                <Trans i18nKey={keyPrefix + `.guideTooltip.${bullet}`} t={t} />
                              </ListItem>
                            ))}
                          </List>
                        </Paper>
                      }
                    >
                      <Box>
                        <LightBulbIconButton
                          onClick={handleGuideTooltipOpen}
                          ariaLabel={t(keyPrefix + '.guideTooltip.ariaLabel')}
                        />
                      </Box>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </Stack>
            </Stack>
            {meeting?.reviewers && meeting?.reviewers?.length > 0 && (
              <Typography variant="body2" sx={{ textAlign: 'right', opacity: 0.6, mb: { xs: 1, sm: 0 } }}>
                {t(keyPrefix + '.reviewCountOfTotal', {
                  reviewsMade: uniqueUserReviewsCount,
                  reviewsPossible: meeting.reviewers.length
                })}
              </Typography>
            )}
          </Stack>
          {userHasEditAccess && currentUser && (
            <Stack alignItems={{ sm: 'flex-end' }} sx={{ mt: { xs: 3, sm: 1 } }}>
              <ValidateMinutesButton meetingId={meeting.id} isRevalidate={hasUserAlreadyReviewed} />
            </Stack>
          )}
        </Box>

        <Stack
          component={Paper}
          direction={{ md: 'row' }}
          variant="outlined"
          sx={{
            py: 1,
            px: 3,
            my: 3,
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: 'space-between'
          }}
          gap={1}
        >
          <Box>
            {t(keyPrefix + '.pastMeetingBannerText', { title: meeting.title, committee: committeeType })}
          </Box>
          <Button
            component={RouterLink}
            to={{
              pathname: `/meetings/${meeting.id}/review/agenda`,
              state: { activePath: '/pending-review' }
            }}
          >
            {t('common:viewMeeting')}
          </Button>
        </Stack>

        <MinutesHeaderCard minutes={meeting} userHasEditAccess={userHasEditAccess} />
        <Paper variant="outlined">
          <Stack>
            {filteredTopicsStart?.map((topic, index) => {
              const previousTopic = topics?.[index - 1];
              const nextTopic = topics?.[index + 1];
              const isPreviousTopicMovable =
                index !== 0 && !checkIsUnmovableTopicType(previousTopic?.type || '');
              const isNextTopicMovable = !checkIsUnmovableTopicType(nextTopic?.type || '');

              const isEditable = topic.id === topicEditableObject.topicId;

              return (
                <Box key={topic.id}>
                  <TopicCardMinutesMoveable
                    topic={topic}
                    handleSetTopicEditable={handleSetTopicEditable}
                    order={index + 1}
                    isEditable={isEditable}
                    onSubmitSuccess={handleUnsetTopicEditable}
                    userHasEditAccess={userHasEditAccess}
                    meeting={meeting}
                    isMeeting={true}
                    committee={committee}
                    users={users}
                    participants={participants}
                    meetingId={meeting.id}
                    previousTopicId={isPreviousTopicMovable ? previousTopic?.id : undefined}
                    nextTopicId={isNextTopicMovable ? nextTopic?.id : undefined}
                    isBrandNewTopic={topicEditableObject.isBrandNew && isEditable}
                  />
                  <Divider sx={{ mx: 3 }} />
                </Box>
              );
            })}

            {isFullTopicEditEnabled && (
              <Box sx={{ px: 3 }}>
                <AddTopicButton
                  meeting={meeting}
                  workspaceId={workspace.workspace?.id}
                  setTopicIdEditable={(topicId: string) =>
                    setTopicEditableObject({ topicId, isBrandNew: true })
                  }
                  excludedTopicTypes={[
                    'quorum',
                    'nominations',
                    'agendaApproval',
                    'pastMinutesApproval',
                    'inCamera'
                  ]}
                />
                <Divider />
              </Box>
            )}

            {filteredTopicsEnd?.map((topic, index, array) => {
              const isEditable = topic.id === topicEditableObject.topicId;
              return (
                <Box key={topic.id}>
                  <TopicCardMinutesMoveable
                    topic={topic}
                    handleSetTopicEditable={handleSetTopicEditable}
                    order={notEndTopicCount + index + 1}
                    isEditable={isEditable}
                    onSubmitSuccess={handleUnsetTopicEditable}
                    userHasEditAccess={userHasEditAccess}
                    meeting={meeting}
                    isMeeting={true}
                    committee={committee}
                    users={users}
                    participants={participants}
                    meetingId={meeting.id}
                    isBrandNewTopic={topicEditableObject.isBrandNew && isEditable}
                  />
                  {index !== array.length - 1 && <Divider sx={{ mx: 3 }} />}
                </Box>
              );
            })}
          </Stack>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <EventsTimeline timelineEvents={timelineEvents} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            my: 4,
            alignItems: 'flex-start'
          }}
        >
          <>
            <Button component={RouterLink} to="/pending-review" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {t('common:labelBack')}
            </Button>
            <Button
              component={RouterLink}
              to="/pending-review"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
            <Box sx={{ display: 'flex' }}>
              <RenderPrintButton componentRef={componentRef} variant="outlined" />

              {userHasEditAccess && currentUser && (
                <Box sx={{ ml: 2 }}>
                  <ValidateMinutesButton meetingId={meeting.id} isRevalidate={hasUserAlreadyReviewed} />
                </Box>
              )}
            </Box>
          </>
        </Box>
      </Box>

      <ViewPastMeeting
        pastMeetingId={isAgendaVisible ? meeting.id : undefined}
        pathOnClose={`/meetings/${meeting.id}/review`}
        routerStateOnClose={{ activePath: '/pending-review' }}
      />
    </PageContainer>
  );
};
